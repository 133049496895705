const printTemplate = ({
  columns,
  total,
  total_vat,
  taxable_value,
  toPaidAmount,
  payReturn,
  CASHIER,
  RECEIPT,
  NO,
  DATE,
  TIME,
  companyInfo,
  symbol,
}: any) => {
  try {
    return `<!DOCTYPE html>
<html lang="en">
<head>
<meta charset="UTF-8">
<meta name="viewport" content="width=device-width, initial-scale=1.0">
<title>Receipt</title>
<style>
    body {
      text-align: center;
      font-family: 'Courier New', Courier, monospace;
    }

    .table {
      width: 100%;
      border-collapse: collapse;
    }

    .table th,
    .table td {
      font-size: 14px;
      text-align: left;
      padding: 5px 0;
    }

    .table .price,
    .table .amount,
    .table .vat {
      text-align: right;
    }

    .table th {
      border-bottom: 1px dashed #000;
      border-top: 1px dashed #000;
    }

    .table tr:last-child th,
    .table tr:last-child td {
      border-bottom: 1px dashed #000;
    }

    .mainFooter {
      text-align: center;
      padding-top: 10px;
      border-top: 1px dashed #000;
    }
    .bigText {
      font-size: 24px;
    }
    .smallText {
      font-size: 14px;
    }
  </style>
</head>
<body>
<div class="receipt-container">
<table style="width: 100%;">
    <tr>
      <th class="bigText">${
        companyInfo?.bname ? companyInfo?.bname : "____"
      }</th>
    </tr>
    <tr>
      <td style="text-align: center;">${
        companyInfo?.city ? companyInfo?.city : "___"
      } / ${companyInfo?.fulladdress ? companyInfo?.fulladdress : "___"}</td>
    </tr>
    <tr>
      <td style="text-align: center;">Tel: ${
        companyInfo?.cphoneno ? companyInfo?.cphoneno : "___"
      }</td>
    </tr>
    <tr>
      <td style="text-align: center;">VAT REG.NO: ${
        companyInfo?.taxno ? companyInfo?.taxno : "____"
      }</td>
    </tr>
  </table>
  <table style="width: 100%;">
    <tr>
      <th style="text-align: start;">TABLE NO: ${NO}</th>
    </tr>
    <tr>
      <td style="text-align: start;">RECEIPT: ${RECEIPT}</td>
      <td style="text-align: end;">DATE: ${DATE}</td>
    </tr>
    <tr>
      <td style="text-align: start;">CASHIER: ${CASHIER}</td>
      <td style="text-align: end;">TIME: ${TIME}</td>
    </tr>
  </table>
  <table class="table">
    <tr>
        <th>QTY</th>
        <th>DESCRIPTION</th>
        <th class="price">PRICE</th>
        <th class="vat">${companyInfo?.country == 101 ? "GST" : "VAT"} %</th>
        <th class="amount">AMOUNT</th>
    </tr>
    ${columns
      .map(
        (product: any) => `
        <tr>
            <td>${product.quantity || ""} ${product.product.unit || ""}</td>
            <td>${product.description || ""}</td>
            <td class="price">${product.price || ""}</td>
            <td class="vat">${product.vat || ""} %</td>
            <td class="amount">${product.total || ""}</td>
        </tr>
    `
      )
      .join("")}
</table>
<table style="width: 100%;">
    <tr>
      <td style="text-align: end;">SUBTOTAL: ${symbol} ${taxable_value}</td>
    </tr>
       <tr>
      <td style="text-align: end;">${
        companyInfo?.country == 101 ? "GST" : "VAT"
      } AMOUNT: ${symbol} ${total_vat}</td>
    </tr>
    <tr>
      <th class="bigText" style="text-align: end;">TOTAL: ${symbol} ${total}</th>
    </tr>
 
    <tr>
      <td style="text-align: end;">CASH: ${symbol} ${toPaidAmount}</td>
    </tr>
    <tr>
      <td style="text-align: end;">CHANGE: ${symbol} ${payReturn}</td>
    </tr>
  </table>
  <table class="mainFooter" style="width: 100%;">
    <tr>
      <th class="bigText">THANK YOU</th>
    </tr>
    <tr>
      <td class="smallText">Powerd By</td>
    </tr>
    <tr>
      <th>Tax GO Global</th>
    </tr>
  </table>
</div>
</body>
</html>
        `;
  } catch (error) {
    console.error("err", error);
  }
};

export { printTemplate };
