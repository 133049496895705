import { Pagination, Table } from 'antd';
import { useTranslation } from 'react-i18next';

function OrderTable({ columns, list, relode, meta, setPage }: any) {
    const { t } = useTranslation();

    return (
        <div className='sales-table'>
            <Table
                columns={columns}
                dataSource={list}
                pagination={false}
            />
            <br />
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Pagination
                    total={meta?.itemCount}
                    showSizeChanger
                    showTotal={(total) => `${(t as any)("order.text_4")} ${meta?.itemCount} ${(t as any)("order.text_5")}`}
                    onChange={(page, take) => setPage(page, take)}
                />
            </div>
            <br />
        </div>
    );
};

export default OrderTable;