import { Col, Container, Row } from "react-bootstrap";
import { IoCall, IoLocationSharp } from "react-icons/io5";
import { IoIosMail } from "react-icons/io";
import { CiGlobe } from "react-icons/ci";

import Header from "../../component/header";
import "./styles.scss";
import ConatctForm from "./form";
import { useTranslation } from "react-i18next"

function Contact() {
  const { t } = useTranslation();

  const data = [
    {
      icon: <IoLocationSharp size={30} color="#ffbc49" />,
      title: "Unit 3 Damastown way Mulhuddart D15 XW7K",
    },
    {
      icon: <IoCall size={24} color="#ffbc49" />,
      title: "+35315549629",
    },
    {
      icon: <IoIosMail size={28} color="#ffbc49" />,
      title: "info@taxgoglobal.com",
    },
    {
      icon: <CiGlobe size={28} color="#ffbc49" />,
      title: "https://www.retailxpress.net",
    },
  ];
  return (
    <>
      <Header />
      <Container>
        <div className="main">
          <Row className="justify-content-center align-items-center h-100">
            <Col md={6}>
              <div className="contact-txt1">{(t as any)("websitecontact.intouch")}</div>
              <div className="contact-txt2">
              {(t as any)("websitecontact.intouchpara")}
              </div>
              <br />
              {data?.map((item: any) => {
                return (
                  <div className="contact-Box2">
                    <div className="contact-Box3">
                      <div style={{ paddingRight: 10 }}>{item?.icon}</div>
                      <div className="contact-txt2">{item?.title}</div>
                    </div>
                  </div>
                );
              })}
            </Col>
            <Col md={6}>
              <ConatctForm />
            </Col>
          </Row>
        </div>
      </Container>
    </>
  );
}

export default Contact;
