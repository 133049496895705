import {
  Button,
  Card,
  Form,
  Input,
  notification
} from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { HiOutlineCamera } from "react-icons/hi2";
import { useSelector } from "react-redux";
import Avatar from "../../assets/images/user.png";
import OuterPageHeader from "../../component/OuterHeader/OuterPageHeader";
import ImagePicker from "../../component/imagePicker";
import LoadingBox from "../../component/loadingBox";
import PrefixSelector from "../../component/prefixSelector";
import Header from "../../component/retailHeader/header";
import { GET, PUT } from "../../utils/apiCalls";
import './styles.scss';
import { useTranslation } from "react-i18next";

function StaffProfile() {
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.User);
  const [form] = Form.useForm()

  const [isLoading, setIsLoading] = useState(true);
  const [isBtLoading, setIssBtLoading] = useState(false);
  const [toggle, toggleModal] = useState(false);
  const [staffDetails, setStaffDetails] = useState<any>();

  useEffect(() => {
    fetchStaffDetails()
  }, [])
  const fetchStaffDetails = async () => {
    try {
      const url = 'contactMaster/details/' + user?.staff?.id;
      const { data }: any = await GET(url, null);
      if (data.name) {
        form.setFieldsValue({
          stafname: data?.name,
          email: data?.email,
          staffID: data?.staffId,
          code: data?.mobile
            ? data?.mobile.split(" ")[0]
            : user?.companyInfo?.countryInfo?.phoneCode,
          mobile: data?.mobile && data?.mobile.split(" ")[1],
          telephone: data?.telephone,
          reference: data?.reference,
          address: data?.address,
          postcode: data?.postcode,
        })
        setStaffDetails(data);
        setIsLoading(false)
      } else {
        setIsLoading(false)
      }
    } catch (error) {
      console.error(error);
    }
  };
  const onFinish = async (data: any) => {
    try {
      setIssBtLoading(true);
      let mobile = `${data.code} ${data.mobile}`;
      let obj = {
        name: data?.stafname,
        email: data?.email,
        staffId: data?.staffID,
        mobile: data?.mobile ? mobile : '',
        telephone: data?.telephone,
        reference: data?.reference,
        address: data?.address,
        postcode: data?.postcode,
        image: data?.image,
      };
      let url = 'contactMaster/update/' + user?.staff.id;
      const response: any = await PUT(url, obj);
      if (response.status) {
        await fetchStaffDetails();
        setIssBtLoading(false);
        notification.success({
          message: `success`,
          description: `Profile Updated Succesfully`
        });
        return response
      } else {
        setIssBtLoading(false);
        notification.error({
          message: `failed`,
          description: `profile update failed`
        });
        return response
      }
    } catch (err) {
      notification.error({
        message: `server_error"`,
        description: `profile update failed`
      });
      setIssBtLoading(false);
      return { status: false }
    }
  };
  return (
    <div>
      <div className='d-block d-lg-none'>
        <Header isNotSerach={true} />
      </div>
      <br />
      <Container>
        <OuterPageHeader title={(t as any)("Profile.text")} />
        <br />
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Card>
            <Row>
              <Col md={4}>
                <div className="profile-picture-container">
                  <div className="profile-picture">
                    <img
                      src={
                        staffDetails?.image == null ||
                          staffDetails?.image == ""
                          ? Avatar
                          : staffDetails?.image
                      }
                      className="profile-imgstaff"
                      onClick={() => toggleModal(true)}
                      alt=""
                    />
                    <div
                      className="profile-edit"
                    >
                      <HiOutlineCamera
                        size={50}
                        onClick={() => toggleModal(true)}
                      />
                    </div>
                  </div>
                  <br />
                  <div className="profile-text-box">
                    <div className="profile-txt1">
                      {staffDetails?.name}
                    </div>
                    <div className="profile-txt2">{staffDetails?.address}</div>
                  </div>
                </div>
              </Col>
              <Col md={8}>
                <Card>
                  <Form
                    layout="vertical"
                    onFinish={onFinish}
                    form={form}
                  >
                    <Row>
                      <Col md="6">
                        <label className="formLabel">{(t as any)("Profile.label")}</label>
                        <Form.Item name="stafname" style={{ marginBottom: 10 }}
                          rules={[{ required: true, message: 'First name is required' }]}
                        >
                          <Input placeholder={(t as any)("Profile.label")} size="large" />
                        </Form.Item>
                        <label className="formLabel">{(t as any)("Profile.label_1")}</label>
                        <Form.Item
                          name="email"
                          style={{ marginBottom: 10 }}
                          rules={[
                            {
                              pattern:
                                /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                              message: "Please enter a valid email address",
                            },
                          ]}
                        >
                          <Input
                            placeholder={(t as any)("Profile.label_1")}
                            size="large"
                            type="text"
                          />
                        </Form.Item>

                        <label className="formLabel">{(t as any)("Profile.label_2")}</label>
                        <Form.Item
                          name="staffID"
                          style={{ marginBottom: 10 }}
                          rules={[{ required: true, message: 'Staff ID is required' }]}
                        >
                          <Input placeholder={(t as any)("Profile.label_2")} size="large" />
                        </Form.Item>
                        <label className="formLabel">{(t as any)("Profile.label_3")}</label>
                        <Form.Item
                          name="mobile"
                          style={{ marginBottom: 10 }}
                        >
                          <Input
                            placeholder={(t as any)("Profile.label_3")}
                            size="large"
                            addonBefore={<PrefixSelector />}
                            onInput={(e: any) => {
                              e.currentTarget.value = e.currentTarget.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                          />
                        </Form.Item>
                      </Col>
                      <Col md="6">
                        <label className="formLabel">{(t as any)("Profile.label_4")}</label>
                        <Form.Item name="telephone" style={{ marginBottom: 10 }}>
                          <Input
                            placeholder={(t as any)("Profile.label_4")}
                            size="large"
                            onInput={(e) => {
                              e.currentTarget.value = e.currentTarget.value.replace(
                                /[^0-9]/g,
                                ""
                              );
                            }}
                          />
                        </Form.Item>

                        <label className="formLabel">{(t as any)("Profile.label_5")}</label>
                        <Form.Item
                          name="reference"
                          style={{ marginBottom: 10 }}
                        >
                          <Input placeholder={(t as any)("Profile.label_5")} size="large" />
                        </Form.Item>
                        <label className="formLabel">{(t as any)("Profile.label_6")}</label>
                        <Form.Item name="address" style={{ marginBottom: 10 }}
                        >
                          <Input placeholder={(t as any)("Profile.label_6")} size="large" />
                        </Form.Item>
                        <label className="formLabel">{(t as any)("Profile.label_7")}</label>
                        <Form.Item name="postcode" style={{ marginBottom: 10 }}
                        >
                          <Input placeholder={(t as any)("Profile.label_7")} size="large" />
                        </Form.Item>
                        <Button
                          size="large"
                          type="primary"
                          htmlType="submit"
                          loading={isBtLoading}
                          block
                        >
                          {(t as any)("Profile.text_1")}
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                  <ImagePicker
                    open={toggle}
                    modalClose={() => toggleModal(false)}
                    data={staffDetails}
                    refreshData={fetchStaffDetails}
                    onFinish={(val: any) => onFinish(val)}
                  />
                </Card>
              </Col>
            </Row>
          </Card>
        )}
        <br />
        <br />
        <br />
      </Container>
    </div>
  );
}

export default StaffProfile;
