import { Button, Form, Input, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { useState } from "react";
import { Col } from "react-bootstrap";
import { useTranslation } from "react-i18next"

function ConatctForm() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const onFinish = async (val: any) => {
    setIsLoading(true);
    try {
      let obj = {
        name: val.fullname,
        email: val.email,
        phone: val.phone,
        message: val.message,
      };
      let url = "https://taxgov2-server.taxgoglobal.com/taxgov2/contactus/add";
      axios.post(url, obj).then((response: any) => {
        if (response?.data?.status === true) {
          setIsLoading(false);
          message.success(response?.data?.message);
        }
      });
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };
  return (
    <>
      <Form onFinish={onFinish}>
        <Col md={12}>
          <label className="formLabel">{(t as any)("websitecontact.firstname")}</label>
          <Form.Item
            name={"firstname"}
            rules={[{ required: true, message: "Enter Your Name" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col md={12}>
          <label className="formLabel">{(t as any)("websitecontact.lastname")}</label>
          <Form.Item
            name={"lastname"}
            rules={[{ required: true, message: "Enter Your Last Name" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col md={12}>
          <label className="formLabel">{(t as any)("websitecontact.phone")}</label>
          <Form.Item
            name={"phone"}
            rules={[{ required: true, message: "Enter Your Phone Number" }]}
          >
            <Input size="large" />
          </Form.Item>
        </Col>
        <Col md={12}>
          <label className="formLabel">{(t as any)("websitecontact.message")}</label>
          <Form.Item name={"message"}>
            <TextArea rows={5} />
          </Form.Item>
        </Col>
        <Col md={{ span: 4, offset: 8 }}>
          <Button
            block
            type="primary"
            className="contactForm-Btn"
            size="large"
            htmlType="submit"
            loading={isLoading}
          >
            {(t as any)("websitecontact.submit")}
          </Button>
        </Col>
      </Form>
    </>
  );
}

export default ConatctForm;
