import {
    Card,
    Tag
} from "antd";
import { useEffect, useState } from "react";
import { Col, Container, Row, Table } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import OuterPageHeader from "../../../component/OuterHeader/OuterPageHeader";
import LoadingBox from "../../../component/loadingBox";
import Header from "../../../component/retailHeader/header";
import { GET } from "../../../utils/apiCalls";
import "../styles.scss";
import { useTranslation } from "react-i18next";

function OrderView(props: any) {
    const { t } = useTranslation();
    const { id }: any = useParams();
    const User = useSelector((state: any) => state.User);

    const [isFullLoading, setIsFullLoading] = useState(false);
    const [details, setDetails] = useState<any>({});

    useEffect(() => {
        getOrderDetails();
    }, []);

    const getOrderDetails = async () => {
        setIsFullLoading(true);
        try {
            let url = `order_master/` + id;
            const getOerdDetails: any = await GET(url, null);
            if (getOerdDetails?.status) {
                setDetails(getOerdDetails?.data);
            }
        } catch (err) {
            console.error(err);
        } finally {
            setIsFullLoading(false);
        }
    };



    return (
        <>
            <div className='d-block d-lg-none'>
                <Header isNotSerach={true} />
            </div>
            <br />
            <Container>
                <OuterPageHeader title={(t as any)("order.text_6")} />
                <br />
                {isFullLoading ? (
                    <LoadingBox />
                ) : (
                    <Card>
                        <Row>
                            <Col md="12">
                                <div className="salesInvoice-Header">{(t as any)("order.text_7")}</div>

                                <Table bordered>
                                    <tbody>
                                        <tr>
                                            <td className="items-head">{(t as any)("order.text_8")}</td>
                                            <td>
                                                <strong>{details?.orderId}</strong>
                                            </td>
                                            <td className="items-head">{(t as any)("order.text_9")}</td>
                                            <td className="items-value">
                                                <Tag
                                                    className="paidStatus"
                                                    color={
                                                        details?.orderStatus == 'pending' ? '#FF8000' :
                                                            details?.orderStatus == 'cancelled' ? '#cd201f' :
                                                                details?.orderStatus == 'started' ? '#ffff00' :
                                                                    details?.orderStatus == 'finished' ? '#38BE02' :
                                                                        '#314f40'
                                                    }
                                                >
                                                    {details?.orderStatus}
                                                </Tag>
                                            </td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Col>
                            <Col style={{ overflow: 'auto' }}>
                                <div className="salesInvoice-SubHeader ">{(t as any)("order.text_10")}</div>
                                <Table bordered>
                                    <thead>
                                        <tr>
                                            <th>{(t as any)("order.text_11")}</th>
                                            <th>{(t as any)("order.text_12")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {details?.orderItems?.map((item: any) => {
                                            return (
                                                <tr>
                                                    <td>{item?.productMaster.idescription}</td>
                                                    <td>
                                                        <Tag
                                                            className="paidStatus"
                                                            color="green"
                                                        >
                                                            {item?.quantity}
                                                        </Tag>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Card>
                )}
            </Container>
            <br />
        </>
    );
}

export default OrderView;
