import { Modal, Popover, notification } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { BsThreeDotsVertical } from "react-icons/bs"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import OuterPageHeader from "../../component/OuterHeader/OuterPageHeader"
import CounterModal from "../../component/counterModal/counterModal"
import LoadingBox from "../../component/loadingBox"
import Header from "../../component/retailHeader/header"
import ViewPopover from "../../component/viewPopover"
import { GET, POST, PUT } from "../../utils/apiCalls"
import CounterForm from "./component/form"
import CounterTable from "./component/table"
import { useTranslation } from "react-i18next"

function Counter() {
    const { t } = useTranslation();
    const { user } = useSelector((state: any) => state.User);
    const navigate = useNavigate();

    const [counterList, setCounterList] = useState([]);
    const [data, setData] = useState([]);
    const [meta, setMeta] = useState<any>();
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [counterSearch, setCounterSearch] = useState('');
    const [counterDetails, setCounterDetails] = useState<any>({});
    const [counterModal, setCounterModal] = useState(false);
    const [loding, setLoding] = useState(false);
    const [form, setForm] = useState(false);
    const [counterId, setCounterId] = useState(false);
    const [counters, setCounter] = useState(false);

    const columns = [
        {
            title: (t as any)("counter.text_1"),
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: (t as any)("counter.text_2"),
            dataIndex: "name",
        },
        {
            title: (t as any)("counter.text_3"),
            dataIndex: "balance",
        },
        {
            title: "",
            dataIndex: "",
            align: "center",
            render: (text: any, record: any) => {
                return (
                    <div className="table-title">
                        <Popover
                            content={
                                <ViewPopover
                                    onView={() => {
                                        navigate('/auth/counters/view', { state: record });
                                    }}
                                // OnEdit={() => {
                                //     fetchCounterDetails(record?.id)
                                //     setCounterId(record?.id)
                                // }}
                                />
                            }
                            placement="bottom"
                            trigger={"click"}
                        >
                            <BsThreeDotsVertical size={16} cursor={"pointer"} />
                        </Popover>
                    </div>
                );
            }
        }
    ];

    const fetchStaffCounterList = async () => {
        setLoding(true)
        try {
            let obj = {
                adminId: user.id,
                companyid: user?.staff?.companyid,
                query: counterSearch,
                page: 1,
                take: 10
            }
            let url = `billing_counter/list`;
            const response: any = await POST(url, obj);
            if (response?.status) {
                setCounterList(response.datas);
                setLoding(false)
            } else {
                setLoding(false)
            }
        } catch (error) {
            setLoding(false)
            console.error('------- error -', error)
        }
    }

    const fetchStaffDifineCounterDetails = async () => {
        try {
            let obj = {
                staffid: user?.staff?.id,
                adminId: user.id,
                sDate: dayjs().format('YYYY-MM-DD'),
                companyid: user?.staff?.companyid,
            }
            let url = `counter_details/counter/details`;
            const response: any = await POST(url, obj);
            if (response.status) {
                setCounterDetails(response);
            }
        } catch (error) {
            console.error('------- error -', error)
        }
    }

    const fetchStaffTransactionList = async () => {
        try {
            let obj = {
                adminId: user.id,
                companyid: user?.companyInfo?.id,
                page: page,
                take: take
            }
            let url = `billing_counter/list`;
            const response: any = await POST(url, obj);
            if (response?.status) {
                setData(response.datas);
                setMeta(response.meta);
                setLoding(false);
            } else {
                setLoding(false);
            }
        } catch (error) {
            console.error('------- error -', error)
        }
    }

    const fetchCounterDetails = async (val: any) => {
        try {
            let url = `billing_counter/counter/${Number(val)}`;
            const response: any = await GET(url, null);
            if (response) {
                setCounter(response);
                setForm(true)
            }
        } catch (error) {
            console.error('------- error -', error)
        }
    }

    const onFinish = async (values: any) => {
        try {
            let shiftList: any = []
            values.shift.forEach((shift: any) => {
                let startTime = dayjs(shift.from).format('h:mm A');
                let endTime = dayjs(shift.to).format('h:mm A');
                let shiftObj = {
                    fromtime: startTime,
                    totime: endTime,
                    name: shift.name
                }
                shiftList.push(shiftObj)
            })
            let url = !values.counterid ? `billing_counter/add` : `billing_counter/update/${values.counterid}`
            let METHORD = !values.counterid ? POST : PUT;
            const obj = {
                adminid: user?.id,
                companyid: user?.companyInfo?.id,
                balance: values.balance || 0,
                name: values.name,
                sdate: values.sdate,
                shiftlist: shiftList
            }
            const response: any = await METHORD(url, obj)
            if (response.status) {
                notification.success({
                    message: "success",
                    description: response.message
                })
                setForm(false)
                fetchStaffTransactionList()
            } else {
                notification.error({
                    message: "Failed", description: "Failed to create counter"
                })
            }
        } catch (error) {
            console.error(error)
            notification.error({
                message: "Server Error",
                description: `Failed to create counter !!  Please try again later`
            })
        }
    }
    useEffect(() => {
        fetchStaffCounterList()
        fetchStaffDifineCounterDetails()
    }, [])

    useEffect(() => {
        fetchStaffTransactionList();
    }, [page, take]);

    return (
        <>
            <div className='d-block d-lg-none'>
                <Header isNotSerach={true} />
            </div>
            <br />
            <Container>
                <OuterPageHeader title={(t as any)("counter.text")} />
                <br />
                {/* <Row>
                    <Col lg={6}></Col>
                    <Col lg={3}>
                        <Button
                            block
                            size="large"
                            type="primary"
                            style={{ marginBottom: '10px' }}
                            onClick={() => navigate('/Auth/counters/update')}
                        >
                            Open Denomination Update
                        </Button>
                    </Col>
                    <Col lg={3}>
                        <Button
                            block
                            size="large"
                            type="primary"
                            style={{ marginBottom: '10px' }}
                            onClick={() => setForm(true)}
                        >
                            Create New Counter
                        </Button>
                    </Col>
                </Row> */}
                {loding ? (
                    <LoadingBox />
                ) : (
                    <>
                        {/* {counter?.id ? (
                            <CounterDetails
                                counterList={counterList}
                                counterSearch={(val: any) => setCounterSearch(val)}
                                title={true}
                                loding={() => fetchStaffCounterList()}
                            />
                        ) : (
                            <div>
                                <NoData text={'You are not clocked in!'} />
                                <Row>
                                    <Col xs={12} md={4}></Col>
                                    <Col xs={12} md={4}>
                                        <Button
                                            size="large"
                                            type="primary"
                                            block
                                            onClick={() => setCounterModal(true)}
                                        >
                                            Clock In
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )} */}
                        <CounterTable
                            columns={columns}
                            data={data}
                            meta={meta}
                            onPage={(page: any, pageSize: any) => {
                                setPage(page)
                                setTake(pageSize)
                            }} />
                    </>
                )}
                <br />
                <br />
            </Container>
            {counterModal ? (
                <Modal
                    open={counterModal}
                    width={700}
                    closable={false}
                    footer={false}
                >
                    <CounterModal
                        counterList={counterList}
                        counterSearch={(val: any) => setCounterSearch(val)}
                        onClose={() => setCounterModal(false)}
                        counterDetails={counterDetails?.data?.counter}
                        setClockIn={() => console.log('nallath')}
                    />
                </Modal>
            ) : null}
            {form && (
                <Modal
                    open={form}
                    width={700}
                    closable={false}
                    onCancel={() => {
                        setForm(false)
                        setCounter(false)
                    }}
                    footer={false}
                >
                    <CounterForm
                        onCancel={() => {
                            setForm(false)
                            setCounter(false)
                        }}
                        onSubmit={onFinish}
                        counter={counters}
                        counterId={counterId}
                    />
                </Modal>
            )}
        </>
    )
}

export default Counter