import { Tooltip } from "antd";
import { useState } from "react";
import { CiImageOff } from "react-icons/ci";
import { IoBagAddOutline } from "react-icons/io5";
import FormPurchace from "./purchace-form";
import { FiEdit } from "react-icons/fi";
import ServiceForm from "./form-service";
import ProductForm from "./form-product";

function ProductItemList(props: any) {
  const [openModal, setOpenModal] = useState(false)
  const [productCModal, setProductCModal] = useState(false);

  let totalRate = props?.item?.rate - props?.item?.vatamt

  return (
    <>
      <div className="ProductItem d-none d-lg-block">
        <div>
          <div className="edit-icone">
            <Tooltip className="productItemBtn" placement="top" title={'Edit '}>
              <FiEdit size={20} color="#d3d3d3" onClick={() => setProductCModal(true)} />
            </Tooltip>
          </div>
          {props?.item?.pimage ? (
            <img
              src={props?.item?.pimage}
              className="ProductItemImg"
            />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <CiImageOff color="#d3d3d3" size={40} />
            </div>
          )}
        </div>
        <div className="ProductItemBox">
          <div className="ProductItemtxt1">
            {props?.item?.idescription} ({props?.item?.icode})
          </div>
          <div className="ProductItemtxt2">
            {props?.currencycode} {props?.item?.includevat == '1.00' ?
              totalRate.toFixed(2) :
              props?.item?.rate}
          </div>
          {props.item.itemtype == 'Service' ? null : (
            <div className="ProductItemtxt3">
              <div style={{ color: props?.item?.stock <= 0 ? 'red' : 'green' }}>{props?.item?.stock || 0} {props?.item?.unitDetails?.unit} left</div>
              <Tooltip className="productItemBtn" placement="top" title={'Purchase product.'}>
                <IoBagAddOutline onClick={() => {
                  if (props.counter) {
                    setOpenModal(true)
                  } else {
                    props.setCounterModal()
                  }
                }} color="#36b372" size={30} />
              </Tooltip>
            </div>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-between d-lg-none ProductItemBox">
        <div style={{ width: '100px', height: '100px', position: 'relative', border: '1px solid #d3d3d3', borderRadius: '8px' }}>
          {props?.item?.pimage ? (
            <img
              src={props?.item?.pimage}
              className="ProductItemImg2"
            />
          ) : (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100px' }}>
              <CiImageOff color="#d3d3d3" size={40} />
            </div>
          )}
        </div>
        <div className="d-flex justify-content-between" style={{ width: '75%', marginLeft: '8px' }}>
          <div>
            <div className="ProductItemtxt1">
              {props?.item?.idescription}
            </div>
            <div className="ProductItemtxt2">
              {props?.currencycode} {props?.item?.includevat == '1.00' ?
                totalRate.toFixed(2) :
                props?.item?.rate}
            </div>
            <div className="ProductItemtxt3">{props?.item?.stock || 0} {props?.item?.unitDetails?.unit} left</div>
          </div>
          <div className="edit-icone2">
            <Tooltip className="productItemBtn" placement="top" title={'Edit '}>
              <FiEdit size={26} color="#d3d3d3" onClick={() => setProductCModal(true)} />
            </Tooltip>
            {props.item.itemtype == 'Service' ? null : (
              <Tooltip className="productItemBtn" placement="top" title={'Please purchase your product.'}>
                <IoBagAddOutline onClick={() => {
                  if (props.counter) {
                    setOpenModal(true)
                  } else {
                    props.setCounterModal()
                  }
                }}
                  color="#36b372" size={30} />
              </Tooltip>
            )}
          </div>
        </div>
      </div>
      {openModal &&
        <FormPurchace
          open={openModal}
          close={() => setOpenModal(false)}
          item={props?.item}
          bankList={props.bankList}
          getDatas={() => props.getDatas()}
        />
      }
      {productCModal && props.type == 'Service' ? (
        <ServiceForm
          productCModal={productCModal}
          close={() => setProductCModal(false)}
          getDatas={() => props.getDatas()}
          url={`ProductMaster/update/${props?.item?.id}`}
          id={props?.item?.id}
        />
      ) : (
        <ProductForm
          productCModal={productCModal}
          close={() => setProductCModal(false)}
          getDatas={() => props.getDatas()}
          url={`ProductMaster/update/${props?.item?.id}`}
          id={props?.item?.id}
        />
      )}
    </>
  );
}

export default ProductItemList;
