import { Col, Row } from "react-bootstrap";
import Background1 from "../../../assets/images/1 landing 01.svg";
import Background from "../../../assets/images/1 landing 02.svg";
import Image from "../../../assets/images/1 landing.png";
import Ios from "../../../assets/images/image2.png";
import Android from "../../../assets/images/image3.png";
import Image1 from "../../../assets/images/m1-landing-bg.png";
import Image3 from "../../../assets/images/ico-an-playstore.png";
import { Alert } from "antd";
import { useTranslation } from "react-i18next";

function Block1() {
  const { t } = useTranslation();
  return (
    <>
      {/* <Row style={{margin:0}}>
        <Col className="col-12">
          <Alert
            className="py-3"
            message={
              (t as any)("websitehome.banner")
            }
            type="warning"
            style={{ textAlign: "center" }}
            showIcon
            closable
          />
        </Col>
      </Row> */}
      {/* <br /> */}
      <Row className="g-0">
        <Col md={6} xs={12} className="order-1 order-md-0">
          <Row className="align-items-center">
            <Col md={4} xs={12} className="d-none d-sm-block">
              <div className="Block1-Box1 ">
                <img className="Block1-Image1" src={Background} alt="" />
              </div>
            </Col>
            <Col md={8} xs={12} className="p-0 m-0">
              <div className="Block1-Box3">
                <div className="Block1-Box2 d-none d-sm-block">
                  <img className="Block1-Img2 " src={Background1} alt="" />
                </div>

                <div className="Block1-Box7">
                  <div className="Block1-txt1">
                    {(t as any)("websitehome.appname")}
                  </div>
                  <span className="Block1-txt2">
                    {(t as any)("websitehome.appparagraph")}
                  </span>
                  <div className="Block1-Box4">
                    {/* <div className="Block1-Box5">
                    <img className="Block1-AppImg" src={Ios} alt="" />
                  </div> */}
                    <div className="Block1-Box5 d-none d-sm-block">
                      <a href="https://play.google.com/store/apps/details?id=com.retailexpress&pcampaignid=web_share">
                        <img className="Block1-AppImg2" src={Android} alt="" />
                      </a>
                    </div>
                    <div className="Block1-Box5 d-sm-none d-block">
                      <a href="https://play.google.com/store/apps/details?id=com.retailexpress&pcampaignid=web_share">
                        <img className="Block1-AppImg2" src={Image3} alt="" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6} xs={12} className="">
          <div className="block1-Box6 d-none d-sm-block">
            <img className="img" src={Image} alt="" />
          </div>
          <div className="block1-Box6 d-sm-none d-block">
            <img className="img" src={Image1} alt="" />
          </div>
        </Col>
      </Row>
    </>
  );
}

export default Block1;
