import { Button, Card, Tag, Tooltip } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { GET } from "../../utils/apiCalls";
import { useEffect, useState } from "react";
import OrderTable from "./components/orderTable";
import { Container } from "react-bootstrap";
import OuterPageHeader from "../../component/OuterHeader/OuterPageHeader";
import { useNavigate } from "react-router-dom";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import { useTranslation } from "react-i18next";
import LoadingBox from "../../component/loadingBox";

function Order() {
    const { t } = useTranslation();
    const { user } = useSelector((state: any) => state.User);
    const navigate = useNavigate();

    const [list, setList] = useState([]);
    const [meta, setMeta] = useState();
    const [page, setPage] = useState(1);
    const [take, setTake] = useState(10);
    const [isLoading, setIsLoading] = useState(true);

    const columns: any = [
        {
            title: (t as any)("order.text_2"),
            dataIndex: "created_at",
            render: (date: string) => {
                return (
                    <div style={{ color: "#BDBDBD" }}>
                        {moment(date).format("YYYY-MM-DD")}
                    </div>
                );
            },
        },
        {
            title: (t as any)("order.text_8"),
            dataIndex: "orderId",
        },
        {
            title: (t as any)("order.text_3"),
            dataIndex: "orderStatus",
            render: (status: any) => {
                return (
                    <Tag
                        className="paidStatus"
                        color={status == "pending" ? "#FF8000" : status == "finished" ? '#38BE02' : "#cd201f"}
                    >
                        {status}
                    </Tag>
                );
            },
        },
        {
            title: "",
            dataIndex: "",
            align: "center",
            render: (text: any, record: any) => {
                return (
                    <Tooltip title="View" mouseEnterDelay={0.5}>
                        <PiDotsThreeOutlineFill
                            size={25}
                            style={{ cursor: "pointer", color: "blue" }}
                            onClick={() =>
                                navigate(`/auth/order-view/${record?.id}`)
                            }
                        />
                    </Tooltip>
                );
            },
        },
    ];

    const fetchStaffTransactionList = async () => {
        try {
            // setIsLoading(true);
            let url = `order_master/getAllOrderRetail?companyId=${user?.staff?.companyid}&order=DESC&page=${page}&take=${take}`;
            const response: any = await GET(url, '');
            if (response?.status) {
                setList(response?.data?.data);
                setMeta(response?.data?.meta);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) { }
    };

    useEffect(() => {
        fetchStaffTransactionList();
    }, [page, take]);
    return (
        <Container>
            <br />
            <OuterPageHeader title={(t as any)("order.text")}
                children={
                    <div>
                        <Button type="primary" onClick={() => navigate('/auth/order-create')}>
                            {(t as any)("order.text_1")}
                        </Button>
                    </div>
                }
            />
            <br />
            <Card>
                {isLoading ? (
                    <LoadingBox />
                ) : (
                    <OrderTable
                        columns={columns}
                        list={list}
                        meta={meta}
                        setPage={(page: any, take: any) => {
                            setTake(take)
                            setPage(page)
                        }}
                    />
                )}
            </Card>
        </Container>
    )
}

export default Order