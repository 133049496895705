import DynamicIcon from '../../../navigation/drower/dynamicIcon';
import '../styles.scss';

function ReportCard({ item, length, index }: any) {
    return (
        <>
            <div className='reportCard d-none d-lg-block' style={{marginRight: length == index + 1 ? '0px' : '10px'}}>
                <div className='rCardbox'>
                    <div className='iconDiv'>
                        <DynamicIcon name={item.icon} size={30} />
                    </div>
                    <div className='titleDiv'>{item.title}</div>
                    <DynamicIcon name={item.icon2} size={30} />
                </div>
                <div className='countTxt'>{item.value}</div>
                <div>{item.message} {item.value}</div>
            </div>
            <div className='reportCard d-lg-none' style={{ backgroundColor: item.bgcolor, margin: '5px', }}>
                <div className=''>
                    <div className='titleDiv' style={{ color: item.color}}>{item.title}</div>
                    <div className='countTxt' style={{ color: item.color}}>{item.value}</div>
                </div>
            </div>
        </>
    )
}

export default ReportCard