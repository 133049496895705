import { Modal, Popover, Switch, notification } from "antd";
import Image from "../../assets/images/noData-unscreen.gif";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { jwtDecode } from "jwt-decode";
import counter from "../../retailExpress/counter";
import { useEffect, useState } from "react";
import { Store } from "../../redux/store";
import CounterModal from "../counterModal/counterModal";
import { POST } from "../../utils/apiCalls";
import { clearCounter, clearHoldProducts, clearProduct } from "../../redux/slices/retailExpress";
import { logout } from "../../redux/slices/userSlice";

function NoClockData({ text }: any) {
  const token = useSelector((state: any) => state?.User?.user?.token);
  const dispatch = useDispatch();
  const counter:any = Store?.getState()?.retailExpress?.counter
  const [counterModal, setCounterModal] = useState(false);
  const [clockIn, setClockIn] = useState(counter?.id ? true : false);
  const [counterList, setCounterList] = useState([]);
  const [counterSearch, setCounterSearch] = useState('');
  const { user } = useSelector((state: any) => state.User);
  const [counterDetails, setCounterDetails] = useState<any>({});
  const [logOutOpen, setLogOutOpen] = useState(false);
  const decoded: any = jwtDecode(token);
  let currentDate = new Date();
  let subscriptionEndDate = new Date(decoded?.subscriptionExpiry);
  const fetchStaffCounterList = async () => {
    try {
      let obj = {
        adminId: user.id,
        companyid: user?.staff?.companyid,
        query: counterSearch,
        page: 1,
        take: 10
      }
      let url = `billing_counter/list`;
      const response: any = await POST(url, obj);
      if (response?.status) {
        setCounterList(response.datas);
      }
    } catch (error) {
      console.error('------- error -', error)
    }
  }
  useEffect(() => {
    if (subscriptionEndDate <= currentDate) {
      console.error('--------- your subscription is expired');
    } else {
    //   fetchLedgers()
      fetchStaffCounterList()
    //   if (!counter?.id) {
        // fetchStaffDifineCounterDetails()
    //   }
    }
  }, [])

  const logOut = () => {
    dispatch(clearCounter({}))
    dispatch(clearProduct([]))
    dispatch(clearHoldProducts())
    dispatch(logout({}))
  }
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={Image} alt="" style={{ width: "30%" }} />
      <div style={{ width: 170 }}>
        <div
          className={"sidebarClockIn "}
          style={{ marginBottom: "20px" }}
          onClick={() => {
            if (subscriptionEndDate <= currentDate) {
              notification.error({
                message: "Expired",
                description: "Your subscription is expired",
              });
              console.error("--------- your subscription is expired");
            } else {
                setCounterModal(true)
                setClockIn(!clockIn)
            }
          }}
        >
          <div>
            {true
              ? (t as any)("sidebar.text_10")
              : (t as any)("sidebar.text_11")}
            &nbsp;&nbsp;
            <Switch checked={clockIn} loading={false} />
          </div>
        </div>
      </div>
      <div style={{ position: "relative", color: "#d3d3d3", fontWeight: 500 }}>
        {text}
      </div>
      {counterModal ? (
        <Modal
          open={counterModal}
          width={700}
          closable={false}
          footer={false}
        >
          <CounterModal
            counterList={counterList}
            counterSearch={(val: any) => setCounterSearch(val)}
            onClose={() => setCounterModal(false)}
            counterDetails={counterDetails?.data?.counter}
            setClockIn={() => setClockIn(counter?.id ? true : false)}
            logOut={() => logOut()}
            logOutOpen={logOutOpen}
          />
        </Modal>
      ) : null}
    </div>
  );
}

export default NoClockData;
