import { Button, Card, Form, Input, Popover } from "antd"
import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useTranslation } from "react-i18next"
import { BsThreeDotsVertical } from "react-icons/bs"
import { CgSearch } from "react-icons/cg"
import { useSelector } from "react-redux"
import OuterPageHeader from "../../component/OuterHeader/OuterPageHeader"
import Header from "../../component/retailHeader/header"
import ViewPopover from "../../component/viewPopover"
import { POST } from "../../utils/apiCalls"
import CustomerForm from "./components/creatModal"
import CustomerTable from "./components/customerTable"
import LoadingBox from "../../component/loadingBox"

function Customer() {
    const { t } = useTranslation();
    const { user } = useSelector((state: any) => state.User);
    const [form] = Form.useForm();

    const [list, setList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [onFilter, setOnFilter] = useState("all");
    const [customerForm, setCustomerForm] = useState(false);
    const [customerId, setCustomerId] = useState(false);

    const fetchStaffTransactionList = async () => {
        try {
            setIsLoading(true);
            let obj = {
                companyid: user?.staff?.companyid,
                status: null,
                page: 1,
                take: 10
            };
            let url = `retailCustomer/getRetailCustomer`;
            const response: any = await POST(url, obj);
            if (response?.status) {
                setList(response?.data);
                setIsLoading(false);
            } else {
                setIsLoading(false);
            }
        } catch (error) { }
    };

    const columns: any = [
        {
            title: (t as any)("customer.text_1"),
            dataIndex: "name",
        },
        {
            title: (t as any)("customer.text_2"),
            dataIndex: "card_number",
        },
        {
            title: (t as any)("customer.text_3"),
            dataIndex: "phonenumber",
        },
        {
            title: (t as any)("customer.text_4"),
            dataIndex: "email",
        },
        {
            title: (t as any)("customer.text_5"),
            dataIndex: "outstanding",
        },
        // {
        //     title: "status",
        //     dataIndex: "status",
        //     render: (status: any) => {
        //         return (
        //             <Tag
        //                 className="paidStatus"
        //                 color={status == "closed" ? "#38BE02" : "#cd201f"}
        //             >
        //                 {status}
        //             </Tag>
        //         );
        //     },
        // },
        {
            title: "",
            dataIndex: "",
            align: "center",
            render: (text: any, record: any) => {
                return (
                    <div className="table-title">
                        <Popover
                            content={
                                <ViewPopover
                                    // onView={() => {
                                    //     setCustomerId(record?.id)
                                    //     setCustomerForm(true)
                                    // }}
                                    OnEdit={() => {
                                        setCustomerId(record?.id)
                                        setCustomerForm(true)
                                    }}
                                />
                            }
                            placement="bottom"
                            trigger={"click"}
                        >
                            <BsThreeDotsVertical size={16} cursor={"pointer"} />
                        </Popover>
                    </div>
                );
            }
        },
    ];

    const onValuesChange = (val: any) => {
        if (val.status) {
            if (val.status == 'all') {
                setOnFilter('')
            } else {
                setOnFilter(val.status);
            }
        }
    };

    useEffect(() => {
        fetchStaffTransactionList()
    }, [])

    return (
        <div>
            <div className='d-block d-lg-none'>
                <Header isNotSerach={true} />
            </div>
            <br />
            <Container>
                <OuterPageHeader title={(t as any)("customer.text")}
                    children={
                        <div>
                            <Button type="primary" onClick={() => setCustomerForm(true)}>
                                {(t as any)("customer.text_6")}
                            </Button>
                        </div>
                    }
                />
                <br />
                <Card>
                    <div className="sala-box">
                        <Form
                            form={form}
                            onValuesChange={onValuesChange}
                            initialValues={{
                                status: onFilter,
                            }}
                        >
                            <Row>
                                <Col md={8} lg={9} />
                                <Col md={5} lg={3}>
                                    <Form.Item name="query">
                                        <Input size="large" placeholder={(t as any)("customer.text_7")} prefix={<CgSearch size={20} />} />
                                    </Form.Item>
                                </Col>
                                {/* <Col xs={5} md={3} lg={2}>
                                    <Form.Item name="status">
                                        <Select
                                            size="large"
                                            style={{ width: '100%' }}
                                            placeholder="Select Status..."
                                        >
                                            <Select.Option key="all">All</Select.Option>
                                            <Select.Option key="open">Open</Select.Option>
                                            <Select.Option key="closed">Closed</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col> */}
                                <Col md={12}>
                                    {isLoading ? (
                                        <LoadingBox />
                                    ) : (
                                        <CustomerTable
                                            list={list}
                                            columns={columns}
                                        />
                                    )}
                                </Col>
                            </Row>
                        </Form>
                    </div>
                </Card>
            </Container>
            {customerForm &&
                <CustomerForm
                    open={customerForm}
                    close={() => setCustomerForm(false)}
                    relode={() => fetchStaffTransactionList()}
                    id={customerId}
                />
            }
        </div>
    )
}

export default Customer