import API from "../config/api";
import { Store } from "../redux/store";

const S3FileUpload = require("react-s3").default;
window.Buffer = window.Buffer || require("buffer").Buffer;
const GETBASEURL = async (url: any, params: any) => {
  return new Promise(async (resolve, reject) => {
    fetch(API.MASTER_BASE_URL + url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const CREATEBASEURL = async (url: any, body: any) => {
  const User = Store.getState().User;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(API.MASTER_BASE_URL + url, {
        method: "post",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(body),
      });
      const json = await response.json();
      resolve(json);
    } catch (error) {
      reject(error);
    }
  });
};
const REGISTERPOST = async (url: any, body: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const REGISTERGET = async (url: any, body: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const GET = async (url: any, params: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(User.baseUrl + url, {
      method: "get",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const POST = async (url: any, body: any) => {
    const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(User.baseUrl + url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const FORGETPASSWORD = async (url: any, body: any) => {
  return new Promise(async (resolve, reject) => {
    fetch( url, {
      method: "post",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer `,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const POST2 = async (url: any, body: any) => {
  const User = Store.getState().User;
  const token: any = User.token;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(User.baseUrl + url, {
        method: "post",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: body,
      });
      const json = await response.json();
      resolve(json);
    } catch (error) {
      reject(error);
    }
  });
};

const PUT = async (url: any, body: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(User.baseUrl + url, {
      method: "put",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())

      .then((json) => {
        resolve(json);
      })

      .catch((error) => {
        reject(error);
      });
  });
};

const DELETE = async (url: any) => {
  const User: any = Store.getState().User;
  const token = User.token;
  return new Promise(async (resolve, reject) => {
    fetch(User.baseUrl + url, {
      method: "delete",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response) => response.json())
      .then((json) => {
        resolve(json);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const UPLOADEXCEL = async (url: any, formData: any) => {
  const User: any = Store.getState().User;
  return new Promise(async (resolve, reject) => {
    try {
      const response = await fetch(User.baseUrl + url, {
        method: "POST",
        body: formData,
      });
      resolve(response);
    } catch (error) {
      reject(error);
    }
  });
};

const COMPRESS_IMAGE = async (file: any) => {
  const User: any = Store.getState().User;
  return new Promise(async (resolve, reject) => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        const response = await fetch(`${User.baseUrl}img_compress/compress`, {
          method: "POST",
          body: formData,
        });
        if (response.ok) {
          const jsonResponse: any = await response.json();
          const obj = {
            ...jsonResponse,
            url: jsonResponse.Location,
            status: true,
          };
          resolve(obj);
        } else {
          let obj = {
            status: false,
            url: null,
          };
          reject(obj);
        }
      } else {
        reject("no file selected");
      }
    } catch (err) {
      reject(err);
    }
  });
};

export {
  GET,
  POST,
  PUT,
  DELETE,
  POST2,
  UPLOADEXCEL,
  CREATEBASEURL,
  GETBASEURL,
  REGISTERPOST,
  REGISTERGET,
  COMPRESS_IMAGE,
  FORGETPASSWORD
};
