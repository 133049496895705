import { Button, Pagination, Tabs, notification } from 'antd'
import { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import OuterPageHeader from '../../../component/OuterHeader/OuterPageHeader'
import LoadingBox from '../../../component/loadingBox'
import NoData from '../../../component/nodata'
import { addOrders, addOrdersQuantity, clearOrder, enterOrderQuantity, minusOrderQuantity, removeOrders } from '../../../redux/slices/retailExpress'
import { GET, POST } from '../../../utils/apiCalls'
import ListItem from './Items'
import ListOrderdItem from './oderdItems'
import '../styles.scss'
import { useTranslation } from 'react-i18next'

function OrderCreate() {
    const { t } = useTranslation();
    const { user } = useSelector((state: any) => state.User);
    const dispatch = useDispatch();
    const order = useSelector((state: any) => state.retailExpress.order);

    const [categories, setCategories] = useState<any>();
    const [category, setCategory] = useState(0);
    const [data, setData] = useState([]);
    // const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(true);
    const [product, setProduct] = useState();
    const [totalQuantity, setTotalQuantity] = useState(0);
    const [total, setTotal] = useState(0);
    const [tax, setTax] = useState(0);
    const [meta, setMeta] = useState<any>();
    const [page, setPage] = useState(1);
    const [btLoding, setBtLoading] = useState(false);

    const fetchData = async () => {
        setLoading(true);
        try {
            const body = {
                id: user?.id,
                companyid: user?.staff?.companyid,
                type: 'Stock',
                category: category,
                name: product || 0,
                barcode: null
            };
            const url = `ProductMaster/ProductListWithOrder?order=DESC&page=${page}&take=8`;
            const response: any = await POST(url, body);
            if (response?.data?.length) {
                const products = response.data.map((item: any) => ({
                    ...item,
                    selection: false
                }));
                setData(products);
                setMeta(response?.meta)
            } else {
                setData([]);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
            setData([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchCategories = async () => {
        try {
            const url = `ProductCategory/user/${user?.id}/${user?.staff?.companyid}`;
            const response: any = await GET(url, '');
            if (response?.data?.length) {
                let categoryData = response.data.map((item: any) => ({
                    key: item.id,
                    label: item.category.toUpperCase()
                }));
                setCategories((prevCategories: any) => [{ key: 0, label: (t as any)("order.text_14") }, ...categoryData]);
            }
        } catch (error) {
            console.error("Error fetching categories:", error);
        }
    };

    useEffect(() => {
        fetchCategories();
        fetchData();
    }, [user, category, page, product]);

    useEffect(() => {
        const calculateTotals = () => {
            let taxAmount = 0;
            let totalQty = 0;
            const sumOfProducts = order?.reduce((acc: any, item: any) => {
                totalQty += item.quantity_no;
                const itemTotal = Number(item.rate) * Number(item.quantity_no);
                const itemTax = Number(item.vatamt) * Number(item.quantity_no);
                taxAmount += itemTax;
                const finalPrice = item.includevat === "1.00" ? itemTotal - itemTax : itemTotal;
                return acc + finalPrice;
            }, 0);
            setTotalQuantity(totalQty);
            setTotal(sumOfProducts);
            setTax(taxAmount);
        };

        calculateTotals();
    }, [order]);

    const handleAddToCart = (item: any) => {
        const existingItem = order?.find((val: any) => val.id === item.id);
        if (existingItem) {
            dispatch(addOrdersQuantity(existingItem?.id));
        } else {
            dispatch(addOrders({ ...item, quantity_no: 1 }));
        }
    };

    const handleOrderQuantityChange = (action: string, val: any) => {
        switch (action) {
            case 'add':
                dispatch(addOrdersQuantity(val));
                break;
            case 'minus':
                dispatch(minusOrderQuantity(val));
                break;
            case 'enter':
                dispatch(enterOrderQuantity(val));
                break;
            case 'remove':
                dispatch(removeOrders(val));
                break;
            default:
                break;
        }
    };

    const handleSubmit = async (val?: any) => {
        try {
            setBtLoading(true)
            let orderList = order?.map((item: any) => {
                return {
                    productId: item?.id,
                    quantity: item?.quantity_no
                }
            })
            let obj = {
                companyId: user?.staff?.companyid,
                staffId: user?.staff?.id,
                orderItems: orderList,
                utcOffset: new Date().getTimezoneOffset(),
            }
            let URL = 'order_master/create'
            const { data: createRetail, status, message }: any = await POST(URL, obj);

            if (status) {
                notification.success({
                    message: "Success",
                    description: "Order created successfully",
                });
                dispatch(clearOrder([]));
                fetchData()
                setBtLoading(false)
            } else {
                notification.error({ message: 'Error', description: message });
                setBtLoading(false)
            }
        } catch (error) {
            console.log('---------------- error ----------------- ', error)
            notification.error({ message: 'Error in Sale Creation' });
            setBtLoading(false)
        }
    }
    return (
        // <div>
        <Container>
            <br />
            <OuterPageHeader title={(t as any)("order.text_13")} />
            <br />
            <div style={{ backgroundColor: '#fff', padding: '3px' }}>
                <Row >
                    <Tabs
                        size="small"
                        defaultActiveKey={category.toString()}
                        items={categories}
                        onChange={(val: any) => setCategory(val)}
                        tabBarStyle={{ backgroundColor: "white", paddingLeft: 10 }}
                    />
                    <Col lg={8} >
                        <Container>
                            <Row>
                                {loading ? (
                                    <LoadingBox />
                                ) : data.length ? (
                                    data.map((item, index) => (
                                        <Col lg={3} xs={12} key={index} style={{ marginBottom: 20 }}>
                                            <ListItem
                                                item={item}
                                                onSelect={handleAddToCart}
                                                selectedItem={order}
                                                type={'Stock'}
                                                currencycode={user?.companyInfo?.countryInfo?.symbol}
                                            />
                                        </Col>
                                    ))
                                ) : (
                                    <NoData text={(t as any)("order.text_19")} />
                                )}
                                <br />
                                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Pagination
                                        total={meta?.itemCount}
                                        showSizeChanger={false}
                                        showTotal={(total) => `Total ${meta?.itemCount} List`}
                                        onChange={(page) => setPage(page)}
                                    />
                                </div>
                                <br />
                            </Row>
                        </Container>
                    </Col>
                    <Col lg={4} xs={12}>
                        <div className="order-box1">
                            <div className="order-box2">
                                {order?.length ? order?.map((item: any, index: any) => (
                                    <ListOrderdItem
                                        key={index}
                                        index={index}
                                        item={item}
                                        isChange={(val: any) => handleOrderQuantityChange('remove', val)}
                                        add={(val: any) => handleOrderQuantityChange('add', val)}
                                        minus={(val: any) => handleOrderQuantityChange('minus', val)}
                                        enter={(val: any) => handleOrderQuantityChange('enter', val)}
                                        currencycode={user?.companyInfo?.countryInfo?.symbol}
                                    />
                                )) : <NoData text={(t as any)("order.text_18")} />}
                            </div>
                            <div className="order-box3">
                                {/* <div className="order-box3"> */}
                                <table className="order-table">
                                    <tbody>
                                        <tr>
                                            <td className="order-txt1">{(t as any)("order.text_15")}</td>
                                            <td className="order-txt2">
                                                {user?.companyInfo?.countryInfo?.symbol} {total?.toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="order-txt1">{(t as any)("order.text_16")}</td>
                                            <td className="order-txt2">
                                                {user?.companyInfo?.countryInfo?.symbol} {tax?.toFixed(2)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="order-txt1">{(t as any)("order.text_17")}</td>
                                            <td className="order-txt2">
                                                {user?.companyInfo?.countryInfo?.symbol} {(total + tax).toFixed(2)}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                {/* </div> */}

                            </div>
                            <div style={{ padding: '5px' }}>
                                <Button
                                    block
                                    type="primary"
                                    size="large"
                                    style={{ marginBottom: '10px', }}
                                    onClick={() => handleSubmit()}
                                    loading={btLoding}
                                >
                                    {(t as any)("order.text_1")}
                                </Button>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
            <br />
        </Container>
    );
}

export default OrderCreate;
