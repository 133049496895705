import { Table } from 'antd';
function CustomerTable({ columns, list }: any) {
    return (
        <div className='sales-table'>
            <Table
                columns={columns}
                dataSource={list}
                pagination={false}
            />
            <br />
        </div>
    );
};

export default CustomerTable;