export const DrowerData = [
  {
    id: 1,
    name: "Counter Sales",
    path: "/auth/counter-sale",
    icone: "FiTag",
  },
  {
    id: 2,
    name: "Product List",
    path: "/auth/product",
    icone: "HiOutlineShoppingBag",
  },
  {
    id: 3,
    name: "Sale Invoice",
    path: "/auth/saleInvoice",
    icone: "TbFileInvoice",
  },
  // {
  //   name: "Other Payment",
  //   path: "other-payment",
  //   icone: "BsCashCoin",
  // },
  {
    id: 4,
    name: "Counters",
    path: "/auth/counters",
    icone: "FaDesktop",
  },
  {
    id: 8,
    name: "Customers",
    path: "/Auth/customer",
    icone: "PiUserDuotone",
  },
  {
    id: 5,
    name: "Staff Details",
    path: "/auth/staff-profile",
    icone: "TbListDetails",
  },
  {
    id: 6,
    name: "Report",
    path: "/auth/report",
    icone: "VscGraph",
  },
  {
    id: 7,
    name: "Subscription",
    path: "/auth/subscription",
    icone: "IoStarOutline",
  },
];
