import React from "react";
import { Col, Row } from "react-bootstrap";
import Image from "../../../assets/images/m5-printer.jpg";
import { useTranslation } from "react-i18next"

function Block5() {
  const { t } = useTranslation();

  return (
    <Row className="align-items-center g-0">
      <Col xs={{ order: 2, span: 12 }} md={{ span: 5, offset: 1, order: 1 }}>
        <div className="Block2-Box2">
          <div className="Block-NewTxt">{(t as any)("websitehome.new")}</div>
          <div className="block2-txt1">
          {(t as any)("websitehome.appbill")} <br /> {(t as any)("websitehome.appbill_1")}
          </div>
          <div className="Block1-txt2">
          {(t as any)("websitehome.billpara")}
          </div>
        </div>
      </Col>
      <Col
        xs={{ order: 1, span: 12 }}
        md={{ span: 6, order: 2 }}
        className="order-1 order-md-0"
      >
        <img className="Block4-Img2" src={Image} alt="" />
      </Col>
    </Row>
  );
}

export default Block5;
