import moment from "moment";
import { numberToWords } from "./helpers";
import API from "../../../config/api";

const template1 = ({
  pagetype,
  user,
  customer,
  sale,
  productlist,
  vatTotal,
  netTotal,
  bankList,
  selectedBank,
  isPaymentInfo,
}: any) => {
  try {
    return `<!DOCTYPE html>
    <html xmlns="http://www.w3.org/1999/xhtml" xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office">
    <head>
        <meta http-equiv="Content-type" content="text/html; charset=utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="format-detection" content="date=no" />
        <meta name="format-detection" content="address=no" />
        <meta name="format-detection" content="telephone=no" />
        <meta name="x-apple-disable-message-reformatting" />
        <link href="https://fonts.googleapis.com/css?family=Muli:400,400i,700,700i" rel="stylesheet" />
        <style>
            body {
                margin: 30px;
                font-family: Arial, Helvetica, sans-serif;
            }
            table {
                width: 100%;
                border-collapse: collapse;
            }
            th,
            td {
                padding: 8px;
                text-align: left;
                font-size: 13px;
            }
            th {
                font-weight: 700;
            }
            .header-img {
                width: 100px;
                height: 50px;
                object-fit: cover;
            }
            .bold {
                font-weight: 700;
            }
            .light {
                font-weight: 300;
            }
            .medium {
                font-weight: 500;
                line-height: 22px;
            }
            .medium2 {
              font-weight: 500;
              line-height: 22px;
              text-align: end;
          }
            .bordered {
                border: 1px solid grey !important;
                background-color: white;
            }
            .heading{
                /* width: 100%; */
                background-color: #DFDCDC;
                display: flex;
                justify-content: center;
                align-items: center;
                font-weight: 700;
                padding: 5px;
                font-size: 14px;
            }
        </style>
        <title>Tax GO Invoice</title>
    </head>
    <body>
        <table>
            <tr>
                <td>
                    <img class="header-img"  src="${user.companyInfo.bimage}"  alt="Logo" />
                </td>
                <td><b><center><u>${pagetype ? pagetype : "Invoice"
      }</u><b></center></td>
                <td class="medium" style="text-align: right;line-height: 20px;">
                    <b>Invoice Number :</b>${sale?.invoiceno}<br />
                    <b>Bill Date: </b>${moment(sale?.sdate).format(
        "DD/MM/YYYY"
      )}<br><br />
                    <b> Due Date: </b>${moment(sale?.ldate).format(
        "DD/MM/YYYY"
      )}<br><br />
                </td>
            </tr>
        </table>
        <hr />
        <table>
            <tr>
                <th>From :</th>
                <th>To :</th>
                <th>Account Details :</th>
            </tr>
            <tr>
                <td class="medium">
                ${user?.companyInfo?.bname ? user?.companyInfo?.bname : "-"
      } <br />
                ${user?.companyInfo?.fulladdress
        ? user?.companyInfo?.fulladdress
        : "-"
      }
                <br>
                ${user?.companyInfo?.countryInfo?.name
        ? user?.companyInfo?.countryInfo?.name
        : "-"
      }
                <br>
                ${user?.companyInfo?.countryInfo?.name === "India"
        ? `GSTIN:${user?.companyInfo?.taxno || '__'}`
        : `vat No: ${user?.companyInfo?.taxno || '__'}`
      }
                <br>
                ${user?.phonenumber ? user?.phonenumber : ""}
                <br>
                ${user?.email ? user?.email : ""}
                <br>
                ${user?.companyInfo?.registerno
        ? `Reg. No: ${user?.companyInfo?.registerno}`
        : ""
      }
                </td>
                <td class="medium">
                ${customer?.bus_name ? `${customer?.bus_name} <br> ` : ""}
                ${customer?.address ? `${customer?.address} <br> ` : ""}
                  ${customer?.mobile ? `${customer?.mobile} <br> ` : ""}
                    ${customer?.email ? `${customer?.email} <br> ` : ""}
                    GSTIN:${customer?.vat_number ? `${customer?.vat_number || '__'} <br> ` : ""
      }
              ${sale.deladdress
        ? `<b>Delivery Address</b><br>
                  ${sale?.deladdress || '__'}
                `
        : ""
      }
                </td>
                <td class="medium">
                ${selectedBank
        ? `
                    Bank Name : ${selectedBank?.laccount || '__'} <br />
                    Account Name : ${selectedBank?.accountname || '__'}<br />
                    Account Number : ${selectedBank?.accnum || '__'}<br />
                    ${selectedBank?.branch == "" || selectedBank?.branch == null
          ? ""
          : `
                        Branch: ${selectedBank?.branch || '__'}
                    <br>
                    `
        }
                    ${selectedBank?.ifsc == "" || selectedBank?.ifsc == null
          ? ""
          : `
                        IFSC: ${selectedBank?.ifsc || '__'}
                    <br>
                    `
        }
                    `
        : ""
      }
                </td>
            </tr>
        </table>
        <hr />
        <table>
            <tr class="heading">
                <td >Product Details</td>
            </tr>
        </table>
        <table>
            <tr class="bordered">
                <th class="bordered">ITEM CODE</th>
                <th class="bordered">DESCRIPTION</th>
                <th class="bordered">PRICE</th>
                <th class="bordered">QTN</th>
                <th class="bordered">UNIT</th>
                <th class="bordered">Discount <br>(Amt & %)</th>
                ${user?.companyInfo?.tax === "vat"
        ? `<th class="bordered"><b>Vat <br>(Amt & %)</b></td>`
        : `<th class="bordered"><b>GST <br>(Amt & %)</b></td>`
      }
                <th class="bordered">TOTAL</th>
            </tr>
            ${productlist
        .map(
          (product: any) => `
            <tr class="bordered">
                <td class="bordered">
                ${product?.product?.icode
              ? product?.product?.icode
              : product.product?.idescription
                ? `- ${product?.product?.idescription}`
                : ""
            }
                </td>
                <td class="bordered">${product?.description ? product?.description : ""
            }</td>
                <td class="bordered">${product?.costprice ? product?.costprice : ""
            }</td>
                <td class="bordered">${product?.quantity ? product?.quantity : ""
            }</td>
                <td class="bordered">${product?.product?.unit ? product?.product?.unit : ""
            }</td>
                <td class="bordered">${product?.discount ? product?.discount : "-"
            }</td>
                ${user?.companyInfo?.tax === "vat"
              ? `<td class="bordered">${product?.incomeTaxAmount} @ ${product?.incomeTax}</td>`
              : `<td class="bordered">${product?.incomeTaxAmount} @ ${product?.incomeTax}</td>`
            }
                <td class="bordered">
                ${product?.total
              ? `${user?.companyInfo?.countryInfo?.symbol}${product?.total}`
              : ""
            }</td>
                `
        )
        .join("")}
            </tr>
        </table>
        <br>
        <table class="bordered">
        <tr>
         <th>Invoice Descriptions</th>
        <th>Terms and Conditions</th>
        <th></th>
        </tr>
        <tr>
           <td class="medium">
                   ${sale?.quotes === null || sale?.quotes == ""
        ? "-"
        : sale?.quotes
      } <br />
                  </td>
                  <td class="medium">
                  ${sale?.terms === null || sale?.terms === "" ? "-" : sale?.terms
      } <br />
                  </td>
                  <td class="medium2">
                  <b>Taxable Value:</b> ${netTotal
        ? `${user?.companyInfo?.countryInfo?.symbol} ${parseFloat(
          netTotal.toFixed(2)
        )}`
        : ""
      } <br />
                  <b>Total VAT:</b>  ${vatTotal
        ? `${user?.companyInfo?.countryInfo?.symbol} ${parseFloat(vatTotal.toFixed(2)) || ""
        }`
        : ""
      }<br />
                  <b>Overall Discount:</b>
                        ${user?.companyInfo?.countryInfo?.symbol} 0  
                    <br />
                  <b>Grand Total:</b>  ${sale.total
        ? `${user?.companyInfo?.countryInfo?.symbol} ${Number(
          sale.total
        )}`
        : ""
      }<br/>
                  ${numberToWords(Number(sale.total).toFixed(2)) || ""}
                   <br/>
                  </td>
              </tr>
        </table>
        <br/>
        <table class="bordered">
        <tr>
        <th ><u>Company Sign and Seal</u></th>
        <th><u>Receiver Sign and Seal</u></th>
         </tr>
          <tr>
        <td>
        <br>
         <br>
               <br>
        </td>
              <td>
        <br>
         <br>
               <br>
        </td>
        </tr>
        </table>
        <hr>
        <table>
        <tr>
        <td margin-top: 25px;><center>Created by <a href="https://www.retailxpress.net/">Retail Xpress Limited</a></center></td>
        </tr>
        </table>
    </body>
    </html>`;
  } catch (error) {
    console.error("err", error);
  }
};

export { template1 };
