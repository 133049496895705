import { Breadcrumb } from "antd";
import { IoArrowBackSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import './styles.scss';
import { useTranslation } from "react-i18next";

function OuterPageHeader(props: any) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="PageHeader-Box" style={{ borderRadius: "10px", background: '#f6efe7', padding: '10px' }}>
      <Row align={"middle"} gutter={[16, 16]}>
        <Col md={9} className="d-flex align-items-center">
          <div onClick={() => navigate(-1)} className="PageHeader-box2">
            <IoArrowBackSharp size={25} color="#000" cursor={"pointer"} />
          </div>
          <div className="px-3 pageHeader-breadcrumb">
            <div className="pageHeader-title">
              {props.title}
            </div>
            <Breadcrumb>
              <Breadcrumb.Item>
                <Link to="/auth/counter-sale">{(t as any)("common.title")}</Link>
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </Col>
        {props?.children ? (
          <Col lg={3} className="pageHeader-children">
            <div style={{ display: 'flex', justifyContent: 'end', alignItems: 'center', height: '100%' }}>{props?.children}</div>
          </Col>
        ) : null}
      </Row>
    </div>
  );
}

export default OuterPageHeader;
