import { Button, ConfigProvider, Form, Input, notification } from 'antd';
import { useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import image from "../../assets/images/forget-password.png";
import Logo from "../../assets/images/logoNew-removebg-preview.png";
import Header from '../../component/header';
import { FORGETPASSWORD, GETBASEURL } from '../../utils/apiCalls';
import { jwtDecode } from 'jwt-decode';

function ChangePassword() {
    const [form] = Form.useForm();
    const { token } = useParams();
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false);
    const [passwordValidation, setPasswordValidation] = useState<any>({
        status: "",
        help: "",
    });
    let mail: any

    if (token) {
        const decoded: any = jwtDecode(token);
        mail = decoded.data.mail
    }
    const onFinish = async (values: any) => {
        try {
            setIsLoading(true)
            let endpoint = "base/active/email/" + mail;
            const response: any = await GETBASEURL(endpoint, {});
            if (response.status) {
                let url = response?.data?.baseUrl + 'contactMaster/changePassword';
                var loginRes: any = await FORGETPASSWORD(url, {
                    password: values.password,
                    token: token
                });
                if (loginRes.status) {
                    notification.success({
                        message: "Success",
                        description: 'Password update success',
                    });
                    setIsLoading(false);
                    navigate('/login')
                } else {
                    notification.error({
                        message: "Failed",
                        description: loginRes?.data?.name == 'TokenExpiredError' ? 'Your password reset link has expired.' : loginRes?.message,
                    });
                    setIsLoading(false);
                }
            } else {
                notification.error({
                    message: "Server Error",
                    description: response.message,
                });
            }
        } catch (error) {
            console.error(error);
            notification.error({
                message: "Server Error",
                description: "Failed to update Password,Please try again later",
            });
            setIsLoading(false);
        }
    };

    const onValuesChange = (value: any, arr: any) => {
        if (value.password) {
            const minLength = 8;
            const specialCharacterRegex = /[.*@!#%&()^~]/;
            const digitRegex = /\d/;
            const uppercaseRegex = /[A-Z]/;
            const lowercaseRegex = /[a-z]/;
            const repetitiveRegex = /(.)\1{2,}/;

            if (value.password.length === 0) {
                setPasswordValidation({
                    status: "error",
                    help: "Password is required",
                });
            } else if (!uppercaseRegex.test(value.password)) {
                setPasswordValidation({
                    status: "error",
                    help: "Password must contain at least one uppercase letter.",
                });
            } else if (!lowercaseRegex.test(value.password)) {
                setPasswordValidation({
                    status: "error",
                    help: "Password must contain at least one lowercase letter.",
                });
            } else if (!specialCharacterRegex.test(value.password)) {
                setPasswordValidation({
                    status: "error",
                    help: "Password must contain at least one special character.",
                });
            } else if (!digitRegex.test(value.password)) {
                setPasswordValidation({
                    status: "error",
                    help: "Password must contain at least one digit.",
                });
            } else if (value.password.length < minLength) {
                setPasswordValidation({
                    status: "error",
                    help: "Password must be at least 8 characters long.",
                });
            } else if (repetitiveRegex.test(value.password)) {
                setPasswordValidation({
                    status: "error",
                    help: "Please enter a strong password.",
                });
            } else {
                setPasswordValidation({
                    status: "success",
                    help: "",
                });
            }
        }
    };

    const compareToFirstPassword = (item: any, value: any) => {
        const targetField = item.field == 'confirm' ? 'password' : 'confirm';
        const firstPassword = form.getFieldValue(targetField);
        if (value && value !== firstPassword) {
            return Promise.reject('The two passwords do not match!');
        } else {
            return Promise.resolve();
        }
    };
    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#18a762",
                    fontFamily: "Regular",
                    borderRadius: 4,
                    colorTextPlaceholder: "#888c99",
                },
            }}
        >
            <div>
                <Header />
                <br />
                <br />
                <Container>
                    <Row>
                        <Col className="d-none  d-lg-flex justify-content-center align-items-center">
                            <div className="login_box" >
                                <img src={image} alt="" />
                            </div>
                        </Col>
                        <Col className="d-flex justify-content-center align-items-center">
                            <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', width: '80%' }}>
                                <div className="loginScreen-Box2">
                                    <img src={Logo} alt="" />
                                </div>
                                <div className="login_head">Change password</div>
                                <div className="login_subhead" style={{ color: 'red' }}>Protect your account with a unique password at least 8 characters long.</div>
                                <Form
                                    onFinish={onFinish}
                                    onValuesChange={onValuesChange}
                                    form={form}
                                    style={{ width: '100%' }}
                                >
                                    <Form.Item
                                        name="password"
                                        hasFeedback
                                        rules={[
                                            {
                                                required: true,
                                                message: "Please enter your new password!",
                                            },
                                            {
                                                validator: compareToFirstPassword,
                                            },

                                        ]}
                                        validateStatus={passwordValidation.status}
                                        help={passwordValidation.help}
                                    >
                                        <Input.Password size='large' placeholder="New password (8-60 characters eg: Example@12)" />
                                    </Form.Item>

                                    <Form.Item
                                        name="confirm"
                                        dependencies={["password"]}
                                        hasFeedback
                                        rules={[
                                            {
                                                validator: compareToFirstPassword,
                                            },

                                        ]}
                                    >
                                        <Input.Password size='large' placeholder="Re-enter new password" />
                                    </Form.Item>
                                    <Form.Item>
                                        <Button
                                            block
                                            size="large"
                                            type="primary"
                                            htmlType="submit"
                                            style={{ height: 45 }}
                                            loading={isLoading}
                                        >
                                            Change Password
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <br />
                <br />
            </div>
        </ConfigProvider>
    )
}

export default ChangePassword