import { useEffect, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import "./styles.scss";
import { Tooltip } from "antd";

const Calculator = (props: any) => {
  const [result, setResult] = useState("");
  const textInput = useRef<any>(null);
  useEffect(() => {
    textInput?.current?.focus?.();
  }, []);

  const handleKeyDown = (event: any) => {
    const keyPressed = event.key;
    if (
      (keyPressed >= "0" && keyPressed <= "9") ||
      keyPressed === "+" ||
      keyPressed == "-" ||
      keyPressed === "*" ||
      keyPressed === "/" ||
      keyPressed === "."
    ) {
      setResult(result);
    } else if (keyPressed == "Enter") {
      calculate();
    } else if (keyPressed == "Delete") {
      reset();
    } else if (keyPressed === "Backspace") {
      event.preventDefault();
      backspace();
    }
  };

  const handleInput = (event: any) => {
    const value = event.target.value;
    setResult(value);
  };

  const onClick = (button: any) => {
    if (button === "=") {
      calculate();
    } else if (button === "C") {
      reset();
    } else if (button === "CE") {
      backspace();
    } else {
      setResult((prevResult) => prevResult + button);
    }
    textInput?.current?.focus?.();
  };

  const calculate = () => {
    try {
      setResult(eval(result).toString());
    } catch (error) {
      setResult("Error");
    }
  };

  const reset = () => {
    setResult("");
  };

  const backspace = () => {
    setResult((prevResult) => prevResult.slice(0, -1));
  };

  return (
    <div className="calculator-body">
      <div style={{ textAlign: 'end' }}>
        <Tooltip
          title="Close Calculater"
          mouseEnterDelay={0.5}
          overlayInnerStyle={{
            fontSize: "14px",
          }}
          placement={"bottom"}><IoClose size={30} color="red" onClick={() => props.setVisible()} /></Tooltip>
      </div>
      <div className="calculator-result">
        <input
          className="calculator-Input"
          autoFocus
          value={result}
          onChange={handleInput}
          onKeyDown={handleKeyDown}
          ref={textInput}
          onInput={(e) => {
            e.currentTarget.value =
              e.currentTarget.value.replace(
                /[^0-9+\-*/.]/g, 
                ""
              );
          }}
        />
      </div>
      <div className="calculator-button">
        <button name="(" onClick={() => onClick("(")}>
          (
        </button>
        <button name=")" onClick={() => onClick(")")}>
          )
        </button>
        <button style={{ color: "red" }} name="CE" onClick={() => onClick("CE")}>
          CE
        </button>
        <button name="C" onClick={() => onClick("C")}>
          C
        </button>
        <br />

        {[1, 2, 3, "+"].map((button: any) => (
          <button key={button} name={button} onClick={() => onClick(button)}>
            {button}
          </button>
        ))}
        <br />

        {[4, 5, 6, "-"].map((button: any) => (
          <button key={button} name={button} onClick={() => onClick(button)}>
            {button}
          </button>
        ))}
        <br />

        {[7, 8, 9, "*"].map((button: any) => (
          <button key={button} name={button} onClick={() => onClick(button)}>
            {button}
          </button>
        ))}
        <br />

        {[".", 0, "=", "/"].map((button: any) => (
          <button key={button} name={button} onClick={() => onClick(button)}>
            {button === "=" ? "=" : button}
          </button>
        ))}
        <br />
      </div>
    </div>
  );
};

export default Calculator;
