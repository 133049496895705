import Image from '../../assets/images/noData-unscreen.gif'

function NoData({ text }: any) {
    return (
        <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
            <img src={Image} alt='' style={{width: '30%'}} />
            <div style={{position: 'relative', color: '#d3d3d3', fontWeight: 500}}>{text}</div>
        </div>
    )
}

export default NoData