import { Drawer, Modal, Switch, notification } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { RxAvatar } from "react-icons/rx";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Logo from "../../assets/images/logoNew-removebg-preview.png";
import CounterModal from '../../component/counterModal/counterModal';
import ReceiptModal from '../../component/otherPeyment/ReceiptModal';
import { addCounter, clearCounter, clearHoldProducts, clearProduct } from '../../redux/slices/retailExpress';
import { logout } from '../../redux/slices/userSlice';
import { GET, POST } from '../../utils/apiCalls';
import { DrowerData } from './drower';
import DynamicIcon from './dynamicIcon';
import './styles.scss';
import { jwtDecode } from 'jwt-decode';

function DrawerScreen({ onClose, open }: any) {
    const token = useSelector((state: any) => state?.User?.user?.token);

    const { user } = useSelector((state: any) => state.User);
    const { counter } = useSelector((state: any) => state.retailExpress);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [ledgerList, setLedgerList] = useState<any>([]);
    const [receiptModal, setReceiptModal] = useState(false);
    const [counterModal, setCounterModal] = useState(false);
    const [clockIn, setClockIn] = useState(counter?.id ? true : false);
    const [counterList, setCounterList] = useState([]);
    const [counterSearch, setCounterSearch] = useState('');
    const [counterDetails, setCounterDetails] = useState<any>({});
    const [btLoding, setBtLoding] = useState(false);
    const [logOutOpen, setLogOutOpen] = useState(false);

    const decoded: any = jwtDecode(token);
    let currentDate = new Date();
    let subscriptionEndDate = new Date(decoded?.subscriptionExpiry);

    const fetchLedgers = async () => {
        try {
            const url = 'account_master/getMyLedgers/' + user.id + '/' + user?.staff?.companyid;
            const { data }: any = await GET(url, null);
            setLedgerList(data);
        } catch (error) {
            console.error(error);
        }
    };

    const fetchStaffCounterList = async () => {
        try {
            let obj = {
                adminId: user.id,
                companyid: user?.staff?.companyid,
                query: counterSearch,
                page: 1,
                take: 10
            }
            let url = `billing_counter/list`;
            const response: any = await POST(url, obj);
            if (response?.status) {
                setCounterList(response.datas);
            }
        } catch (error) {
            console.error('------- error -', error)
        }
    }

    const fetchStaffDifineCounterDetails = async () => {
        try {
            let obj = {
                staffid: user?.staff?.id,
                adminId: user.id,
                sDate: dayjs().format('YYYY-MM-DD'),
                companyid: user?.staff?.companyid,
            }
            let url = `counter_details/counter/details`;
            const response: any = await POST(url, obj);
            if (response.status) {
                setCounterDetails(response);
            }
        } catch (error) {
            console.error('------- error -', error)
        }
    }

    useEffect(() => {
        if (subscriptionEndDate <= currentDate) {
            console.error('--------- your subscription is expired');
        } else {
            fetchLedgers()
            fetchStaffCounterList()
            if (!counter?.id) {
                fetchStaffDifineCounterDetails()
            }
        }
    }, [])

    const logOut = () => {
        dispatch(clearCounter({}))
        dispatch(clearProduct([]))
        dispatch(clearHoldProducts())
        dispatch(logout({}))
    }

    const fetchOpenData = async () => {
        try {
            let obj = {
                adminId: user?.id,
                companyid: user?.staff?.companyid,
                staffid: user?.staff?.id,
                sDate: new Date(),
            }
            let url = `counter_details/counter/open`;
            const response: any = await POST(url, obj);
            if (response?.status) {
                dispatch(addCounter(response.data));
                setClockIn(response.data.id ? true : false)
            } else {
                dispatch(clearCounter(''))
            }
        } catch (error) {
            console.error('------- error -', error)
        }
    }

    useEffect(() => {
        fetchOpenData()
    }, [])

    return (
        <>
            <Drawer
                placement={'left'}
                width={260}
                onClose={onClose}
                open={open}
                closeIcon={false}
                style={{ margin: 0 }}
            >
                <div className='drawerScreen-logo' onClick={() => navigate('/auth/counter-sale')}>
                    <img src={Logo} />
                </div>
                <div className='drawerContent'>
                    {DrowerData.map((item: any) => {
                        return (
                            <div className='draweText' onClick={() => item.path == 'other-payment' ? setReceiptModal(true) : navigate(item.path)}>
                                <DynamicIcon name={item.icone} size={20} color={item.name == 'Subscription' ? '#ffd700' : '#18a762'} />
                                <div style={{ marginLeft: '15px' }}>{item.name}</div>
                            </div>
                        )
                    })}
                </div>
                <div className='draweClockIn' onClick={() => {
                    if (subscriptionEndDate <= currentDate) {
                        notification.error({
                            message: "Expired", description: 'Your subscription is expired'
                        })
                        console.error('--------- your subscription is expired');
                    } else {
                        setCounterModal(true)
                        setClockIn(!clockIn)
                    }
                }}
                >
                    Clock {!clockIn ? 'In' : 'Out'} &nbsp; &nbsp; <Switch value={clockIn} loading={btLoding} />
                </div>
                <div className='draweText2' onClick={() => {
                    if (!counter?.id) {
                        logOut()
                    } else {
                        setCounterModal(true);
                        setLogOutOpen(true)
                    }
                }}>
                    <DynamicIcon name={'LuLogOut'} size={20} color={'red'} />
                    <div style={{ marginLeft: '15px' }}>Log Out</div>
                </div>
                <div className='draweStaff-Box1' onClick={() => navigate('/auth/company')}>
                    <div className='drawe-image'>
                        {user?.companyInfo?.bimage ? (
                            <img src={user?.companyInfo?.bimage} />
                        ) : (
                            <RxAvatar size={35} />
                        )}
                    </div>
                    <div>
                        <span className='DrawerBName'>{user?.companyInfo?.bname}</span>
                        <span>Staff</span>
                    </div>
                </div>
            </Drawer>
            {receiptModal &&
                <>
                    <Modal
                        open={receiptModal}
                        onCancel={() => setReceiptModal(false)}
                        width={700}
                        footer={false}
                    >
                        <ReceiptModal
                            setModalOpen={setReceiptModal}
                            type={"receipt"}
                            formType={'create'}
                            ledgerList={ledgerList}
                            onCancel={() => setReceiptModal(false)}
                        />
                    </Modal>
                </>
            }
            {counterModal ? (
                <Modal
                    open={counterModal}
                    width={700}
                    closable={false}
                    footer={false}
                >
                    <CounterModal
                        counterList={counterList}
                        counterSearch={(val: any) => setCounterSearch(val)}
                        onClose={() => setCounterModal(false)}
                        counterDetails={counterDetails?.data?.counter}
                        setClockIn={() => setClockIn(counter?.id ? true : false)}
                        logOut={() => logOut()}
                        logOutOpen={logOutOpen}
                    />
                </Modal>
            ) : null}
        </>
    );
}

export default DrawerScreen;
