import React from "react";
import Header from "../../component/header";
import { Accordion, Col, Container, Row } from "react-bootstrap";
import Image2 from "../../assets/images/image3.png";
import { useTranslation } from "react-i18next"

const HelpTutorials = () => {
  const { t } = useTranslation();

  const data = [
    {
      id: 1,
      title: (t as any)("websitetouse.howtosignupweb"),
      description: (t as any)("websitetouse.howtosignupweb1"),
      iframe:
        '<iframe width="280" height="158" src="https://www.youtube.com/embed/Ek3QNNLPh40?si=ugUU2k3RdPTk82eF" frameborder="0" allowfullscreen></iframe>',
      link: "https://www.youtube.com/watch?v=Ek3QNNLPh40",
    },
    {
      id: 2,
      title: (t as any)("websitetouse.howtosignupapp"),
      description:  (t as any)("websitetouse.howtosignupapp1"),
      iframe:
        '<iframe width="280" height="158" src="https://www.youtube.com/embed/u1WWJFnsWMU?si=JxrMq1LLcZalVXJl" frameborder="0" allowfullscreen></iframe>',
      link: "https://youtu.be/u1WWJFnsWMU?si=nmjYizPTlqy-q9Pp",
    }
  ];


  return (
    <div >
      <Header />
      <div>
      <br />
      <div style={{ textAlign: "center" }}>
        <div className="block2-txt1">
        {(t as any)("websitetouse.howtouse")} 
        </div>
        <div className="Block1-txt2">
        {(t as any)("websitetouse.tousepara")}
          <br />
          {(t as any)("websitetouse.tousepara_1")}
        </div>
      </div>
      <br />
      <br />
      <Container>
        <Row className="supportcards-parent">
          <Col md="12">
            {data.map((item: any, index: number) => (
              <Accordion>
                <Accordion.Item eventKey={index.toString()} key={index}>
                  <Accordion.Header>
                    {item.id}. {item.title}
                  </Accordion.Header>
                  <Accordion.Body>
                    <Row>
                      <Col md={3}>
                        <p>
                          {item.description}:{" "}
                          <a href={item.link} target="_blank" rel="noreferrer">
                            Go to youtube
                          </a>
                        </p>
                      </Col>
                      <Col md={3}>
                          <div
                            dangerouslySetInnerHTML={{ __html: item.iframe }}
                          />
                        </Col>
                        <Col md={6}></Col>
                    </Row>
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            ))}
          </Col>
          <Col />
        </Row>
      </Container>
      </div>
      <Row >
        <Col md={12} className="footer-Box1 g-0">
          <div className="footer-Txt1">{(t as any)("websitehome.needhelp")}</div>
          <div className="footer-Txt2">{(t as any)("websiteheader.contact")}</div>
          <div className="footer-Txt1">info@taxgoglobal.com</div>
          <div className="d-flex align-items-center justify-content-center">
            <a href="https://play.google.com/store/apps/details?id=com.retailexpress&pcampaignid=web_share">
              <img className="footer-Img" src={Image2} alt="" style={{width:"200px",height:"100px"}}/>
            </a>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default HelpTutorials;
