import { Button, DatePicker, Form, Input, Modal, Select, notification } from 'antd'
import { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { POST } from '../../../utils/apiCalls'
import { useTranslation } from 'react-i18next'

const ClosePayment = (props: any) => {
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.User)

  const [isCloseLoading, setIsCloseLoading] = useState(false);


  const onFinish = async (values: any) => {
    try {
      setIsCloseLoading(true)
      let url = "StaffTransactions/add/pymentcreatetoledeger";
      let obj = {
        staffTransactions: props?.selectedRow,
        bankid: values?.ledger,
        paidmethod: values?.paidmethod,
        date: values?.sdate,
        reference: values?.reference,
        companyid: user?.companyid
      }
      const response: any = await POST(url, obj)
      if (response.status) {
        notification.success({
          message: "Success",
          description: "Payment closed successfully"
        })
        props?.setModalOpen()
        props.refresh()
      } else {
        notification.error({
          message: "Failed",
          description: "Failed to close payment"
        })
      }
    } catch (error) {
      console.error(error)
      notification.error({
        message: "Server Error",
        description: "Failed to close payment! Please try again later"
      })
    } finally {
      setIsCloseLoading(false)
    }
  }

  return (
    <>
      <Modal
        open={props?.isOpen}
        onCancel={() => props?.setModalOpen()}
        width={700}
        footer={false}
      >
        <Form onFinish={onFinish}>
          <Row>
            <Col className="Table-Txt" md={12}>
              {(t as any)("invoice.text_12")}
            </Col>
            <br />
            <br />
            <hr />
            <Col md={6}>
              <div className="formItem">
                <label className="formLabel">{(t as any)("invoice.label")}</label>
                <Form.Item
                  name="ledger"
                  rules={[
                    {
                      required: true,
                      message: (t as any)("invoice.required"),
                    },
                  ]}
                >
                  <Select
                    size="large"
                    placeholder={(t as any)("invoice.placeholder")}
                    filterOption={false}
                  >
                    {props.bankList?.map((item: any) =>
                      <Select.Option value={item?.id} key={item?.id}>
                        {item?.laccount}
                      </Select.Option>
                    )
                    }
                  </Select>
                </Form.Item>
              </div>
              <div className="formItem">
                <label className="formLabel">{(t as any)("invoice.label_1")}</label>
                <Form.Item
                  name="paidmethod"
                  rules={[
                    {
                      required: true,
                      message: (t as any)("invoice.required_1"),
                    },
                  ]}
                >
                  <Select size="large" placeholder={(t as any)("invoice.placeholder_1")}>
                    <Select.Option key={"cash"}>{(t as any)("invoice.label_1.1")}</Select.Option>
                    <Select.Option key={"cheque"}>{(t as any)("invoice.label_1.2")}</Select.Option>
                    <Select.Option key={"electronic"}>{(t as any)("invoice.label_1.3")}</Select.Option>
                    <Select.Option key={"card"}>{(t as any)("invoice.label_1.4")}</Select.Option>
                    <Select.Option key={"paypal"}>{(t as any)("invoice.label_1.5")}</Select.Option>
                  </Select>
                </Form.Item>
              </div>
            </Col>
            <Col md={6}>
              <div className="formItem">
                <label className="formLabel">{(t as any)("invoice.label_2")}</label>
                <Form.Item
                  name="sdate"
                  rules={[
                    {
                      required: true,
                      message: (t as any)("invoice.required_2"),
                    },
                  ]}
                >
                  <DatePicker placeholder={(t as any)("invoice.text_46")} style={{ width: "100%" }} size="large" />
                </Form.Item>
              </div>

              <div className="formItem">
                <label className="formLabel">{(t as any)("invoice.label_3")}</label>
                <Form.Item name="reference">
                  <Input size="large" placeholder={(t as any)("invoice.placeholder_2")} />
                </Form.Item>
              </div>
            </Col>
            <Col md={6}></Col>
            <Col md={6}>
              <Button
                type="primary"
                htmlType="submit"
                block
                size="large"
                loading={isCloseLoading}
              >
                {(t as any)("invoice.text_11")}
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  )
}

export default ClosePayment