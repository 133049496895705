import { Button, Modal, notification } from "antd"
import dayjs from "dayjs"
import { useEffect, useState } from "react"
import { Col, Container, Row } from "react-bootstrap"
import { useDispatch, useSelector } from "react-redux"


import { useNavigate } from "react-router-dom"
import OuterPageHeader from "../../../component/OuterHeader/OuterPageHeader"
import CounterModal from "../../../component/counterModal/counterModal"
import LoadingBox from "../../../component/loadingBox"
import NoData from "../../../component/nodata"
import Header from "../../../component/retailHeader/header"
import { POST, PUT } from "../../../utils/apiCalls"
import CounterDetails from "./details"
import CounterForm from "./form"

function ShiftUpdate() {
    const { user } = useSelector((state: any) => state.User);
    const { counter } = useSelector((state: any) => state.retailExpress);

    const [counterList, setCounterList] = useState([]);
    const [counterSearch, setCounterSearch] = useState('');
    const [counterDetails, setCounterDetails] = useState<any>({});
    const [counterModal, setCounterModal] = useState(false);
    const [loding, setLoding] = useState(false);
    const [form, setForm] = useState(false);
    const [counterId, setCounterId] = useState(false);
    const [counters, setCounter] = useState(false);

    const fetchStaffCounterList = async () => {
        setLoding(true)
        try {
            let obj = {
                adminId: user?.id,
                companyid: user?.staff?.companyid,
                query: counterSearch,
                page: 1,
                take: 10
            }
            let url = `billing_counter/list`;
            const response: any = await POST(url, obj);
            if (response?.status) {
                setCounterList(response.datas);
                setLoding(false)
            } else {
                setLoding(false)
            }
        } catch (error) {
            setLoding(false)
            console.error('------- error -', error)
        }
    }

    const fetchStaffTransactionList = async () => {
        try {
            let obj = {
                adminId: user?.id,
                companyid: user?.companyInfo?.id,
                page: 1,
                take: 10
            }
            let url = `billing_counter/list`;
            const response: any = await POST(url, obj);
            if (response?.status) {
                // setData(response.datas);
                // setMeta(response.meta);
                setLoding(false);
            } else {
                setLoding(false);
            }
        } catch (error) {
            console.error('------- error -', error)
        }
    }

    const onFinish = async (values: any) => {
        try {
            let shiftList: any = []
            values.shift.forEach((shift: any) => {
                let startTime = dayjs(shift.from).format('h:mm A');
                let endTime = dayjs(shift.to).format('h:mm A');
                let shiftObj = {
                    fromtime: startTime,
                    totime: endTime,
                    name: shift.name
                }
                shiftList.push(shiftObj)
            })
            let url = !values.counterid ? `billing_counter/add` : `billing_counter/update/${values.counterid}`
            let METHORD = !values.counterid ? POST : PUT;
            const obj = {
                adminid: user?.id,
                companyid: user?.companyInfo?.id,
                balance: values.balance || 0,
                name: values.name,
                sdate: values.sdate,
                shiftlist: shiftList
            }
            const response: any = await METHORD(url, obj)
            if (response.status) {
                notification.success({
                    message: "success",
                    description: response.message
                })
                setForm(false)
                fetchStaffTransactionList()
            } else {
                notification.error({
                    message: "Failed", description: "Failed to create counter"
                })
            }
        } catch (error) {
            console.error(error)
            notification.error({
                message: "Server Error",
                description: `Failed to create counter !!  Please try again later`
            })
        }
    }

    const fetchStaffDifineCounterDetails = async () => {
        try {
            let obj = {
                staffid: user?.staff?.id,
                adminId: user?.id,
                sDate: dayjs().format('YYYY-MM-DD'),
                companyid: user?.staff?.companyid,
            }
            let url = `counter_details/counter/details`;
            const response: any = await POST(url, obj);
            if (response.status) {
                setCounterDetails(response);
            }
        } catch (error) {
            console.error('------- error -', error)
        }
    }

    useEffect(() => {
        fetchStaffDifineCounterDetails()
    })
    return (
        <>
            <Header isNotSerach={true} />
            <br />
            <Container>
                <OuterPageHeader title="Denomination Update" />
                <br />
                {loding ? (
                    <LoadingBox />
                ) : (
                    <>
                        {counter?.id ? (
                            <CounterDetails
                                counterList={counterList}
                                counterSearch={(val: any) => setCounterSearch(val)}
                                title={true}
                                loding={() => fetchStaffCounterList()}
                            />
                        ) : (
                            <div>
                                <NoData text={'You are not clocked in!'} />
                                <Row>
                                    <Col xs={12} md={4}></Col>
                                    <Col xs={12} md={4}>
                                        <Button
                                            size="large"
                                            type="primary"
                                            block
                                            onClick={() => setCounterModal(true)}
                                        >
                                            Clock In
                                        </Button>
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </>
                )}
            </Container>
            {counterModal ? (
                <Modal
                    open={counterModal}
                    width={700}
                    closable={false}
                    footer={false}
                >
                    <CounterModal
                        counterList={counterList}
                        counterSearch={(val: any) => setCounterSearch(val)}
                        onClose={() => setCounterModal(false)}
                        counterDetails={counterDetails?.data?.counter}
                        setClockIn={() => console.log('nallath')}
                    />
                </Modal>
            ) : null}
            {form && (
                <Modal
                    open={form}
                    width={700}
                    closable={false}
                    onCancel={() => {
                        setForm(false)
                        setCounter(false)
                    }}
                    footer={false}
                >
                    <CounterForm
                        onCancel={() => {
                            setForm(false)
                            setCounter(false)
                        }}
                        onSubmit={onFinish}
                        counter={counters}
                        counterId={counterId}
                    />
                </Modal>
            )}
        </>
    )
}

export default ShiftUpdate