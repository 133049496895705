import { Card, DatePicker, Form, Select, Tag, Tooltip } from "antd";
import dayjs from "dayjs";
import moment from "moment";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { PiDotsThreeOutlineFill } from "react-icons/pi";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import OuterPageHeader from "../../component/OuterHeader/OuterPageHeader";
import LoadingBox from "../../component/loadingBox";
import Header from "../../component/retailHeader/header";
import DrawerScreen from "../../navigation/drower";
import { POST } from "../../utils/apiCalls";
import SaleTable from "./components/table";
import { useTranslation } from "react-i18next";

function SaleInvoiceList() {
  const { t } = useTranslation();
  const { user } = useSelector((state: any) => state.User);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const [isDrawer, setIsDrawer] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [onFilter, setOnFilter] = useState("all");

  const [firstDate, setFirstDate] = useState(dayjs().format("YYYY-MM-DD"));
  const [list, setList] = useState([]);

  const fetchStaffTransactionList = async () => {
    try {
      setIsLoading(true);
      let obj = {
        status: onFilter,
        sDate: firstDate,
        lDate: firstDate,
        adminId: user?.id,
        companyid: user?.staff?.companyid,
        staffid: user?.staff?.id,
        // type: 'Customer Receipt'
      };
      let url = `StaffTransactions/list`;
      const response: any = await POST(url, obj);
      if (response?.status) {
        setList(response.datas);
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) { }
  };

  useEffect(() => {
    fetchStaffTransactionList();
  }, [onFilter, firstDate]);

  const columns: any = [
    {
      title: (t as any)("invoice.text_1"),
      dataIndex: "invoiceno",
      key: 0,
    },
    {
      title: (t as any)("invoice.text_2"),
      dataIndex: "saletype",
    },
    {
      title: (t as any)("invoice.text_3"),
      dataIndex: "created_at",
      render: (date: string) => {
        return (
          <div style={{ color: "#BDBDBD" }}>
            {moment(date).format("YYYY-MM-DD")}
          </div>
        );
      },
    },
    {
      title: (t as any)("invoice.text_4"),
      dataIndex: "total",
    },
    {
      title: (t as any)("invoice.text_47"),
      dataIndex: "paid_amount",
    },
    {
      title: (t as any)("invoice.text_5"),
      dataIndex: "status",
      render: (status: any) => {
        return (
          <Tag
            className="paidStatus"
            color={status == "closed" ? "#38BE02" : "#cd201f"}
          >
            {status}
          </Tag>
        );
      },
    },
    {
      title: (t as any)("invoice.text_6"),
      dataIndex: "paid_status",
      render: (paid_status: any) => {
        return (
          paid_status == 0 ? (
            <Tag className="paidStatus" color={"#cd201f"}>
              {(t as any)("invoice.text_7.1")}
            </Tag>
          ) : paid_status == 1 ? (
            <Tag className="paidStatus" color={"#FE9900"}>
              {(t as any)("invoice.text_7.2")}
            </Tag>
          ) : (
            <Tag className="paidStatus" color={"#38BE02"}>
              {(t as any)("invoice.text_7.3")}
            </Tag>
          )
        );
      },
    },
    {
      title: "",
      dataIndex: "",
      align: "center",
      render: (text: any, record: any) => {
        return (
          record.type == 'Customer Receipt' ? (
            <Tooltip title={(t as any)("invoice.text_8")} mouseEnterDelay={0.5}>
              <PiDotsThreeOutlineFill
                size={25}
                style={{ cursor: "pointer", color: "blue" }}
                onClick={() =>
                  navigate(`/auth/sale-invoice-view/${record?.saleid}`)
                }
              />
            </Tooltip>
          ) : null
        );
      },
    },
  ];

  const onValuesChange = (val: any) => {
    if (val.dateRange) {
      let d1 = dayjs(val.dateRange, "YYYY-MM-DD");
      setFirstDate(d1.format("YYYY-MM-DD"));
    }
    if (val.status) {
      if (val.status == 'all') {
        setOnFilter('')
      } else {
        setOnFilter(val.status);
      }
    }
  };

  const disabledDate = (current: any) => {
    return current && current > dayjs().startOf("day");
  };

  return (
    <div>
      <div className='d-block d-lg-none'>
        <Header isNotSerach={true} />
      </div>
      <Container>
        <br />
        <OuterPageHeader title={(t as any)("invoice.text")} />
        <br />
        {isLoading ? (
          <LoadingBox />
        ) : (
          <Card>
            <div className="sala-box">
              <Form
                form={form}
                onValuesChange={onValuesChange}
                initialValues={{
                  dateRange: dayjs(firstDate, "YYYY-MM-DD"),
                  status: onFilter,
                }}
              >
                <Row>
                  <Col md={5} lg={7} />
                  <Col xs={7} md={4} lg={3}>
                    <Form.Item name="dateRange">
                      <DatePicker disabledDate={disabledDate} size="large" style={{ width: '100%' }} />
                    </Form.Item>
                  </Col>
                  <Col xs={5} md={3} lg={2}>
                    <Form.Item name="status">
                      <Select
                        size="large"
                        style={{ width: '100%' }}
                        placeholder={(t as any)("invoice.text_9")}
                      >
                        <Select.Option key="all">{(t as any)("invoice.text_9.1")}</Select.Option>
                        <Select.Option key="open">{(t as any)("invoice.text_9.2")}</Select.Option>
                        <Select.Option key="closed">{(t as any)("invoice.text_9.3")}</Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <SaleTable
                      list={list}
                      columns={columns}
                      relode={fetchStaffTransactionList}
                    />
                  </Col>
                </Row>
              </Form>
            </div>
          </Card>
        )}
        <br />
        <br />
        <br />
        <br />
      </Container>
      {isDrawer && (
        <DrawerScreen
          open={isDrawer}
          onClose={() => setIsDrawer(false)}
          logOut={() => console.log("")}
        />
      )}
    </div>
  );
}

export default SaleInvoiceList;
