import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/images/logoNew-removebg-preview.png";
import "./styles.scss";
import flag from "../../assets/images/Arabic.png";
import flag2 from "../../assets/images/UK.png";
import flag3 from "../../assets/images/mongoliyan.png";
import flag4 from "../../assets/images/India.png";
import flag5 from "../../assets/images/malay.png";
import { IoMenu } from "react-icons/io5";
import { useState } from "react";
import { Drawer, Select } from "antd";
import { useTranslation } from "react-i18next";

function Header() {
  const id = Number(localStorage.getItem("id")) || 1;
  const language: any = [
    {
      id: 1,
      value: "en",
      title: "English",
      flag: flag2,
    },
    {
      id: 2,
      value: "ar",
      title: "العربية",
      flag: flag,
    },
    {
      id: 3,
      value: "ml",
      title: "Монгол",
      flag: flag3,
    },
    {
      id: 4,
      value: "hi",
      title: "हिन्दी",
      flag: flag4,
    },
    {
      id: 5,
      value: "ms",
      title: "Melay",
      flag: flag5,
    },
  ];

  const { t, i18n } = useTranslation();
  const { user } = useSelector((state: any) => state.User);
  const [drawer, setDrawer] = useState(false);
  const navigate = useNavigate();
  const handleChangeLanguage = (value?: any) => {
    const languageData = language.find((item: any) => item?.id == value);
    if (languageData) {
      i18n.changeLanguage(languageData.value);
      localStorage.setItem("language", languageData?.value);
      localStorage.setItem("id", languageData.id);
    } else {
      const languageData: any = localStorage.getItem("language");
      i18n.changeLanguage(languageData);
    }
  };
  return (
    <>
      <div className={"header-Box1"}>
        <Row className="align-items-center g-0">
          <Col md={7} sm={10} xs="10">
            <img
              className="header-Logo"
              src={Logo}
              alt=""
              onClick={() => navigate("/")}
            />
          </Col>
          <Col md={5} sm={2} xs="2">
            <div className="header-Box-mobile2">
              <IoMenu onClick={() => setDrawer(true)} size={28} />
            </div>
            <div className="header-Box2">
              <div className="header-Txt2" onClick={() => navigate("/")}>
                {(t as any)("websiteheader.home")}
              </div>
              <div className="header-Txt2" onClick={() => navigate("/support")}>
                {(t as any)("websiteheader.support")}
              </div>
              <div
                className="header-Txt2"
                onClick={() => navigate("/help-tutorials")}
              >
                {(t as any)("websiteheader.touse")}
              </div>
              <div className="header-Txt2" onClick={() => navigate("/contact")}>
                {(t as any)("websiteheader.contact")}
              </div>
              <div className="header-Txt2" onClick={() => navigate("/login")}>
                {(t as any)("websiteheader.login")}
              </div>
              <div className="header-Txt2" onClick={() => navigate("/signup")}>
                {(t as any)("websiteheader.signup")}
              </div>
              <div className="header-Txt2">
                <Select
                  style={{ width: "100%" }}
                  bordered={false}
                  defaultValue={id}
                  onSelect={(val: any) => handleChangeLanguage(val)}
                >
                  {language.map((lang: any) => (
                    <Select.Option value={lang.id} key={lang.id}>
                      <img
                        style={{
                          width: "30px",
                          height: "15px",
                          marginRight: "5px",
                        }}
                        src={lang.flag}
                      />
                      {lang.title}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Drawer
        onClose={() => setDrawer(false)}
        open={drawer}
        children={
          <div className="header-Box4">
            <>
              {" "}
              <img
                className="header-Logo"
                src={Logo}
                alt=""
                onClick={() => {
                  navigate("/");
                  setDrawer(false);
                }}
              />
            </>
            <div
              className="header-Txt2"
              onClick={() => {
                navigate("/");
                setDrawer(false);
              }}
            >
              {(t as any)("websiteheader.home")}
            </div>
            <div
              className="header-Txt2"
              onClick={() => {
                navigate("/support");
                setDrawer(false);
              }}
            >
              {(t as any)("websiteheader.support")}
            </div>
            <div
              className="header-Txt2"
              onClick={() => {
                navigate("/contact");
                setDrawer(false);
              }}
            >
              {(t as any)("websiteheader.contact")}
            </div>
            <div
              className="header-Txt2"
              onClick={() =>
                !user.staff
                  ? (navigate("/login"), setDrawer(false))
                  : (navigate("/auth/counter-sale"), setDrawer(false))
              }
            >
              {(t as any)("websiteheader.login")}
            </div>
            <div
              className="header-Txt2"
              onClick={() => {
                navigate("/signup");
                setDrawer(false);
              }}
            >
              {(t as any)("websiteheader.signup")}
            </div>
            <div className="header-Txt2">
              <Select
                style={{ width: "100%" }}
                bordered={false}
                defaultValue={id}
                onSelect={(val: any) => handleChangeLanguage(val)}
              >
                {language.map((lang: any) => (
                  <Select.Option value={lang.id} key={lang.id}>
                    <img
                      style={{
                        width: "30px",
                        height: "15px",
                        marginRight: "5px",
                      }}
                      src={lang.flag}
                    />
                    {lang.title}
                  </Select.Option>
                ))}
              </Select>
            </div>
          </div>
        }
      ></Drawer>
    </>
  );
}

export default Header;
