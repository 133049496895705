import { Button, Form, Input, Modal, notification } from 'antd';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { GET, POST, PUT } from '../../../utils/apiCalls';
import QRScan from './scaner';
import { useTranslation } from 'react-i18next';

function CustomerForm({ close, open, id, relode }: any) {
    const { t } = useTranslation();
    const { user } = useSelector((state: any) => state.User);
    const [form] = Form.useForm();
    
    const [isLoading, setIsLoading] = useState(false);
    const [qrScan, setQrScan] = useState(false);
    const [qrData, setQRData] = useState(false);

    const onFinish = async (val: any) => {
        setIsLoading(true);
        try {
            let obj = {
                companyid: user?.staff?.companyid,
                ...val,
            };
            let METHOD = id ? PUT : POST;
            let url = id ? `retailCustomer/update/${id}` : 'retailCustomer/retailCustomer/add';
            let res: any = await METHOD(url, obj);
            if (res.status) {
                notification.success({
                    message: res.message,
                });
                form.resetFields();
                setIsLoading(false);
                relode(res.data)
                close();
            } else {
                notification.error({
                    message: res.message,
                });
                setIsLoading(false);
            }
        } catch (error) {
            console.error(error);
            notification.error({
                message: 'Server Error. Try again.',
            });
            setIsLoading(false);
        }
    };

    // Custom validation rule to ensure either email or phone number is provided
    const atLeastOneRequired = ({ getFieldValue }: any) => ({
        validator(_: any, value: any) {
            if (getFieldValue('email') || getFieldValue('phonenumber')) {
                return Promise.resolve();
            }
            return Promise.reject(new Error((t as any)("customer.required")));
        },
    });

    const fechCostomer = async () => {
        try {
            let url = `retailCustomer/findOne/${id}`;
            const responce: any = await GET(url, null);
            let data = responce.data
            if (data.card_number) {
                setQRData(true)
            }
            if (responce?.status) {
                form.setFieldsValue({
                    phonenumber: data.phonenumber,
                    email: data.email,
                    card_number: data.card_number,
                    name: data.name,
                })
            }
        } catch (err) {
            console.error(err);
        }
    }
    useEffect(() => {
        if (id) {
            fechCostomer()
        }
    })

    // const qrCodeSuccessCallback = async (decodedText: any,) => {
    //     if (decodedText) {
    //         console.log('-------------- decodedText', decodedText)
    //     }
    // };
    // const qrCodeSuccessCallback = (decodedText: string) => {
    //     console.log('------------', decodedText)
    //     // props.qrCodeSuccessCallback(decodedText)
    //     // setVisible(false);
    // };

    // const qrCodeErrorCallback = (errorMessage: string) => {
    //     console.error('Error scanning QR code:', errorMessage);
    // };
    // useEffect(() => {
    //     // if (visible) {
    //     console.log('------------- mishab')
    //     const html5QrcodeScanner = new Html5QrcodeScanner(
    //         "html5qr-code-full-region",
    //         {
    //             fps: 10,
    //             qrbox: 250,
    //             aspectRatio: 1,
    //             disableFlip: false,
    //         },
    //         true
    //     );
    //     html5QrcodeScanner.render(qrCodeSuccessCallback, qrCodeErrorCallback);
    //     return () => {
    //         html5QrcodeScanner.clear().catch((error: any) => {
    //             console.error("Failed to clear html5QrcodeScanner. ", error);
    //         });
    //     };
    //     // }
    // }, []);
    return (
        <>
            <Modal
                open={open}
                width={500}
                closable={true}
                footer={false}
                onCancel={() => {
                    form.resetFields();
                    close();
                }}
            >
                <div className="Table-Txt">
                    {id ? (t as any)("customer.text_6.1") : (t as any)("customer.text_6.2")} {(t as any)("customer.text_6")}
                </div>
                <div>{id ? (t as any)("customer.text_6.1") : (t as any)("customer.text_6.3")} {(t as any)("customer.text_8.1")} {id ? (t as any)("customer.text_6.1") : (t as any)("customer.text_6.2")} {(t as any)("customer.text_8.2")}</div>
                <hr />
                <br />
                <Form
                    form={form}
                    onFinish={onFinish}
                >
                    <label className="formLabel">{(t as any)("customer.text_9")}</label>
                    <Form.Item
                        name="card_number"
                    >
                        {qrData ? (
                            <Input readOnly />
                        ) : (
                            <Button block onClick={() => setQrScan(true)}>{(t as any)("customer.text_10")}</Button>
                        )}
                    </Form.Item>
                    <label className="formLabel">{(t as any)("customer.text_11")}</label>
                    <Form.Item
                        name="name"
                    >
                        <Input
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <label className="formLabel">{(t as any)("customer.text_12")}</label>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                type: 'email',
                                message: (t as any)("customer.required_1"),
                            },
                            atLeastOneRequired,
                        ]}
                    >
                        <Input
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <label className="formLabel">{(t as any)("customer.text_13")}</label>
                    <Form.Item
                        name="phonenumber"
                        rules={[
                            atLeastOneRequired,
                        ]}
                    >
                        <Input
                            onInput={(e: any) => {
                                e.currentTarget.value = e?.currentTarget?.value?.replace(
                                    /[^0-9]/g,
                                    ""
                                );
                            }}
                            type="number"
                            style={{ width: "100%" }}
                        />
                    </Form.Item>
                    <Button
                        loading={isLoading}
                        type="primary"
                        htmlType="submit"
                        block
                        size="large"
                    >
                        {!id ? (t as any)("customer.text_14.1") : (t as any)("customer.text_14.2")}
                    </Button>
                </Form>
            </Modal>
            {qrScan ? (
                <QRScan
                    open={qrScan}
                    close={() => setQrScan(false)}
                    qrData={(val: any) => {
                        setQRData(true)
                        form.setFieldValue('card_number', val)
                    }}
                />
            ) : null}
        </>
    );
}

export default CustomerForm;
