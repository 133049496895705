import { Col, Row } from "react-bootstrap";
import Image1 from "../../../assets/images/Group 14.png";
import Image2 from "../../../assets/images/2 printer-dots.svg";
import Image3 from "../../../assets/images/3 checkout.png";
import { useTranslation } from "react-i18next"

function Block3() {
  const { t } = useTranslation();

  return (
    <>
      <Row className="align-items-center g-0">
        <Col md={{ span: 4, offset: 4 }} className="d-none d-sm-block">
          <img className="Block-Img1" src={Image2} alt="" />
        </Col>
        <Col md={6} className="order-1 order-md-0">
          <Row className="align-items-center">
            <Col md={4} className="d-none d-sm-block">
              <img className="Block3-Img" src={Image1} alt="" />
            </Col>
            <Col md={8}>
              <div className="Block2-Box2">
                <div className="Block-NewTxt">{(t as any)("websitehome.new")}</div>
                <div className="block2-txt1">
                {(t as any)("websitehome.appability")}
                </div>
                <div className="Block1-txt2">
                {(t as any)("websitehome.abilitypara")}
                </div>
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={6} xs={12}>
          <img className="Block3-Img" src={Image3} alt="" />
        </Col>
      </Row>
    </>
  );
}

export default Block3;
