import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import API from "../../config/api";
import { GET, POST, REGISTERGET, REGISTERPOST } from "../../utils/apiCalls";
import { Button, notification } from "antd";
import { VscVerifiedFilled } from "react-icons/vsc";
import { TbXboxX } from "react-icons/tb";
import "./styles.scss";
import { useNavigate } from "react-router-dom";
import LoadingBox from "../loadingBox";
const UpdateEmail = (props: any) => {
  const { userId, token } = useParams();
  const [data, setData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const { verifyData } = useParams();

  const updateEmail = async () => {
    let url = API.BASE_URL +  API.VERIFY_EMAIL + userId + `/${token}`;
    try {
      setIsLoading(true);
      const response: any = await REGISTERGET(url, null);
      if (response) {
        setData(response);
        notification.success({
          message: "Updated email",
          description: "Your email has been updated successfully.",
        });
      }
    } catch (error) {
      notification.error({
        message: "Error",
        description: "Failed to update email. Please try again later.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const verfyEmail = async () => {
    try {
      setIsLoading(true);
     let url = API.BASE_URL + "user/verifyEmailAddress";
      let obj = {
        verifyData,
      };
      const response: any = await REGISTERPOST(url, obj);
      if (response.status) {
        setData(response?.data);
        notification.success({
          message: "Success",
          description: "Email verified successfully",
        });
      } else {
        notification.error({
          message: "Failed",
          description: "Failed to verify email",
        });
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: "Server Error",
        description: "Failed to verify email!! Please try again later",
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    props?.type === "verify" && verfyEmail();
    props?.type === "update" && updateEmail();
  }, []);

  return (
    <div className="updateEmail-Box1">
      {isLoading ? (
        <LoadingBox />
      ) : data ? (
        <div className="updateEmail-Box2">
          <VscVerifiedFilled color="#18a762" size={50} />
          Email Verified
          <br />
          <br />
          <Button className="inputCards" type="primary" onClick={() => navigate("/login")}>
            Proceed to Login Page
          </Button>
        </div>
      ) : (
        <div className="updateEmail-Box2">
          <TbXboxX color="red" size={50} />
          Email Not Verified
        </div>
      )}
    </div>
  );
};

export default UpdateEmail;
