import { Table } from 'antd'
import NoData from '../../../component/nodata'
import { useTranslation } from 'react-i18next';

function ReportListInvoice({ data, columns }: any) {
    const { t } = useTranslation();

    return (
        <div className='sales-table'>
            {data?.length ? (
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                />
            ) : (
                <NoData text={(t as any)("report.text_17")} />
            )}
        </div>
    )
}

export default ReportListInvoice