import React from "react";
import Header from "../../component/header";
import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";
import { Button } from "antd";
import Image from "../../assets/images/contact.jpg";
import { useTranslation } from "react-i18next"

function Support() {
  const { t } = useTranslation();

  const openWhatsappChat = () => {
    const phoneNumber = "9605583291";
    const whatsappUrl = `https://wa.me/${phoneNumber}`;
    window.open(whatsappUrl, "_blank");
  };
  return (
    <>
      {" "}
      <Header />
      <Container>
        <div className="main">
          <Row className="justify-content-center align-items-center h-100">
            <Col md={6}>
              <div className="support-txt1">{(t as any)("websitesupport.learn")}</div>
              <div className="support-txt2">{(t as any)("websitesupport.gethelp")}</div>
              <div className="support-txt1">
              {(t as any)("websitesupport.getanswers")}
              </div>
              <br />

              <Button
                type="primary"
                className="support-Btn"
                onClick={() => openWhatsappChat()}
              >
                {(t as any)("websitesupport.send")}
              </Button>
            </Col>
            <Col md={6}>
              <div className="support-Box1">
                <img className="support-img" src={Image} alt="" />
              </div>
            </Col>
          </Row>
        </div> 
      </Container>
    </>
  );
}

export default Support;
