import { Input, Popover, Radio } from "antd";
import { useState } from "react";
import { IoCalculatorOutline } from "react-icons/io5";
import { VscThreeBars } from "react-icons/vsc";
import Clock from "react-live-clock";
import DrawerScreen from "../../navigation/drower";
import QRScannerModal from "../../retailExpress/sale/components/scaner";
import Calculator from "../calculator";
import "./styles.scss";
import { Col, Row } from "react-bootstrap";
import { CgSearch } from "react-icons/cg";
import { useTranslation } from "react-i18next";

function Header(props: any) {
  const { t } = useTranslation();

  const [visible, setVisible] = useState(false);
  const [drawer, setDrawer] = useState(false);

  return (
    <div className="RetailExpress-Header">
      <Row className="d-flex">
        <Col xs={props.qrCodeSuccessCallback ? 3 : props.isNotSerach ? 3 : 3} md={props.qrCodeSuccessCallback ? 6 : props.isNotSerach ? 6 : 6} lg={props.qrCodeSuccessCallback ? 1 : props.isNotSerach ? 8 : 4}>
          <VscThreeBars className='d-block d-lg-none' size={30} onClick={() => setDrawer(true)} />
        </Col>
        {/* <Col xs={7} md={4} lg={2} className="d-flex" style={{ textAlign: 'right', marginBottom: '10px' }}>
          <IoCalculatorOutline size={30} onClick={() => setVisible(!visible)} />
          <div className="Navigation-HeaderTimeBox">
            <Clock format={"h:mm:ss A"} ticking={true} />
          </div>
          {visible ? (
            <Popover arrow={false} content={<Calculator setVisible={() => setVisible(false)} />} placement="bottomRight" open={visible}></Popover>
          ) : null}
        </Col> */}

        <Col className="d-flex justify-content-end" xs={9} md={6} lg={5} style={{ marginBottom: '10px' }}>
          <IoCalculatorOutline size={30} onClick={() => setVisible(!visible)} />
          {props?.typeList?.length ? (
            <Radio.Group
              name="radiogroup"
              optionType="button"
              buttonStyle="solid"
              defaultValue={props.type}
              onChange={(e: any) => props.setType(e.target.value)}
              style={{ width: "100%" }}
            >
              {props?.typeList?.map((item: any) => (
                <Radio.Button style={{ width: '50%', textAlign: "center" }} value={item.name}>{item.label}</Radio.Button>
              ))}
            </Radio.Group>
          ) : null}
          {visible ? (
            <Popover arrow={false} content={<Calculator setVisible={() => setVisible(false)} />} placement="bottomRight" open={visible}></Popover>
          ) : null}
        </Col>
        {props.qrCodeSuccessCallback ? (
          <Col md={12} lg={3} style={{ marginBottom: '10px' }}>
            <QRScannerModal
              qrCodeSuccessCallback={props.qrCodeSuccessCallback}
            />
          </Col>
        ) : null}
        {props?.isNotSerach ? null : (
          <Col md={props.qrCodeSuccessCallback ? 12 : 12} lg={3}>
            <Input
              style={{ width: '100%' }}
              placeholder={(t as any)("header.text_3")}
              prefix={<CgSearch size={20} />}
              onChange={(e) => props.product(e.target.value)}
            />
          </Col>
        )}
      </Row>
      {drawer &&
        <DrawerScreen
          open={drawer}
          onClose={() => setDrawer(false)}
        />
      }
    </div>
  );
}

export default Header;
