import React, { useEffect, useState } from 'react'
import { GET } from '../../utils/apiCalls';
import { useSelector } from 'react-redux';
import { PiUserCirclePlusFill } from 'react-icons/pi';
import { RiBuilding2Line } from 'react-icons/ri';
import { RxAvatar } from 'react-icons/rx';
import { useNavigate } from 'react-router-dom';
import DynamicIcon from './dynamicIcon';
import { useTranslation } from 'react-i18next';

function PopoverProfile({ setexpand, logOut }: any) {
    const { t } = useTranslation();
    const { user } = useSelector((state: any) => state.User);
    const navigate = useNavigate();

    const [staffDetails, setStaffDetails] = useState<any>();

    const fetchStaffDetails = async () => {
        try {
            const url = 'contactMaster/details/' + user?.staff?.id;
            const { data }: any = await GET(url, null);
            if (data.name) {
                setStaffDetails(data);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchStaffDetails()
    }, [])

    return (
        <div>
            <div className="popover-head">
                <div className='drawe-image2'
                    onClick={() => {
                        setexpand(0)
                        navigate('/auth/company')
                    }}
                >
                    {staffDetails?.image ? (
                        <img src={staffDetails?.image} />
                    ) : (
                        <RxAvatar size={37} />
                    )}
                </div>
                <div style={{ marginTop: '5px' }}>Hi, {user?.staff?.name}</div>
            </div>
            <div className="popover-button" onClick={() => {
                setexpand(0)
                navigate('/auth/company')
            }}><RiBuilding2Line size={24} />&nbsp; {(t as any)("common.text_4")}</div>
            <div className="popover-button" onClick={() => {
                setexpand(0)
                navigate('/auth/staff-profile')
            }}><PiUserCirclePlusFill size={24} />&nbsp; {(t as any)("common.text_5")}</div>
            <div className="popover-button" onClick={() => logOut()}>
                <DynamicIcon name={'TbLogout'} size={24} color={'red'} />&nbsp; {(t as any)("common.text_6")}
            </div>
            {/* <DynamicIcon name={'LuLogOut'} size={20} />
            <div style={{ marginLeft: '15px' }}>Log Out</div> */}
        </div>
    )
}

export default PopoverProfile