import {
  Button,
  ConfigProvider,
  Form,
  Input,
  notification,
  Select,
} from "antd";
import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import image from "../../assets/images/cash_desk.png";
import Logo from "../../assets/images/logoNew-removebg-preview.png";
import Header from "../../component/header";
import SettingUpModal from "../../component/lodingProgrus";
import API from "../../config/api";
import {
  login,
  setBaseUrl,
  setMailWerify,
  setToken,
  update,
} from "../../redux/slices/userSlice";
import { Store } from "../../redux/store";
import {
  CREATEBASEURL,
  GETBASEURL,
  POST,
  REGISTERPOST,
} from "../../utils/apiCalls";
import "./styles.scss";
import countries from "../../utils/CountriesWithStates.json";
import { useTranslation } from "react-i18next";

function SignupScreen() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [loding, setLoding] = useState(false);
  const [progrusModal, setProgrusModal] = useState(false);
  const [company, setCompany] = useState(0);
  const [staff, setStaff] = useState(-1);
  const [Counter, setCounter] = useState(-1);
  const [product, setProduct] = useState(-1);
  const [percent, setPercent] = useState(0);
  const [register, setRegister] = useState(-1);
  const [passwordValidation, setPasswordValidation] = useState<any>({
    status: "",
    help: "",
  });

  const onFinish = async (formData: any) => {
    try {
      setRegister(0);
      setLoding(true);
      const url = API.BASE_URL + API.LOGIN_REGISTRATION;
      const requestBody = {
        fullName: formData.username,
        email: formData.email,
        password: formData.password,
        countryid: formData.countryid,
        isRetail: true,
      };
      const response: any = await REGISTERPOST(url, requestBody);
      if (response.status) {
        dispatch(setToken(response?.data?.token));
        response.data["password"] = formData.password;
        dispatch(
          login({
            data: response?.data,
            auth: false,
          })
        );
        createBaseUrl(response?.data);
      } else {
        notification.error({
          message: "Fail!",
          description: response.message,
        });
        setRegister(1);
        setLoding(false);
      }
    } catch (error) {
      console.error("Signup failed:", error);
      setRegister(1);
    }
  };

  const createBaseUrl = async (userData: any) => {
    try {
      const url = API.BASE_URL;
      const date = new Date();
      const year = date.getFullYear();
      const lastParams = `${year}-${year + 1}`;
      const body = {
        adminid: userData.id,
        email: userData.email,
        isActive: true,
        urlName: lastParams,
        baseUrl: url,
      };
      const endpoint = "base";
      const response: any = await CREATEBASEURL(endpoint, body);
      if (response?.status) {
        dispatch(setBaseUrl(response?.data?.baseUrl));
        setRegister(2);
        setProgrusModal(true);
        setLoding(false);
        await defualtCompany(userData?.countryid);
      } else {
        setRegister(1);
        setLoding(false);
      }
    } catch (error) {
      console.error("Base URL creation failed:", error);
      setRegister(1);
      setLoding(false);
    }
  };

  const defualtCompany = async (countryid: any) => {
    try {
      setCompany(0);
      const userData: any = Store.getState().User.user;
      let obj = {
        bname: "Default Company",
        bcategory: "Default Category",
        financial_year_start: new Date(),
        adminid: userData?.id,
        userid: userData?.id,
        country: countryid,
        workingTimeFrom: "09:00 AM",
        workingTimeTo: "09:00 PM",
      };
      let url = API.CREATE_COMPANY;
      const response: any = await POST(url, obj);
      if (response.data) {
        let obj = {
          ...userData,
          companyInfo: response.data,
        };
        dispatch(update(obj));
        setPercent(25);
        setCompany(2);
        await defualtStaff();
      } else {
        setCompany(1);
      }
    } catch (error) {
      console.error(error);
      setCompany(1);
    }
  };

  const defualtStaff = async () => {
    try {
      setStaff(0);
      const userData: any = Store.getState().User.user;
      let url = API.CREATE_DEFAULT_STAFF;
      let staffObj = {
        name: userData?.firstname,
        password: userData?.password,
        email: userData?.email,
        adminid: userData?.id,
        createdBy: userData?.id,
        companyid: userData.companyInfo.id,
        userdate: new Date(),
        is_deleted: false,
      };
      const response: any = await POST(url, staffObj);
      if (response.status) {
        setPercent(50);
        setStaff(2);
        await defualtCounter();
      } else {
        setStaff(1);
      }
    } catch (error) {
      console.error(error);
      setStaff(1);
    }
  };

  const defualtCounter = async () => {
    try {
      setCounter(0);
      const userData: any = Store.getState().User.user;

      let url = `billing_counter/addCounter`;
      const obj = {
        adminid: userData.id,
        companyid: userData?.companyInfo?.id,
        balance: 0,
        name: "counter 1",
        sdate: new Date(),
        location: userData?.companyInfo?.newLocation?.id,
        shiftlist: [
          {
            fromtime: "9:00 AM",
            totime: "9:00 PM",
            name: "Day shift",
          },
        ],
      };
      const response: any = await POST(url, obj);
      if (response.status) {
        setCounter(2);
        setPercent(75);
        await defualtProduct();
      } else {
        setCounter(1);
      }
    } catch (error) {
      console.error(error);
      setCounter(1);
    }
  };

  const defualtProduct = async () => {
    try {
      setProduct(0);
      const userData: any = Store.getState().User.user;

      let reqObj = {
        itemtype: "Nonstock",
        icode: "defualt-product",
        idescription: "defualt-product",
        saccount: 4000,
        sp_price: 100,
        rate: 100,
        type: "Nonstock",
        logintype: "user",
        userid: userData.id,
        adminid: userData.id,
        vat: 10,
        vatamt: 10,
        // product_category: "defualt-category",
        date: "2024-04-21T18:30:00.000Z",
        // unit: "NOS",
        // location: "defualt-location",
        createdBy: userData.id,
        companyid: userData?.companyInfo?.id,
        paccount: 0,
        includevat: 0,
        c_price: 0,
        existingstock: false,
      };

      let URL = API.ADD_PRODUCT;
      const response: any = await POST(URL, reqObj);
      if (response.status) {
        setPercent(100);
        setProduct(2);
        await baseUrl();
      } else {
        setProduct(1);
      }
    } catch (error: any) {
      console.error(error);
      setProduct(1);
    }
  };

  const baseUrl = async () => {
    const userData: any = Store.getState().User.user;
    try {
      let endpoint = "base/active/email/" + userData.email;
      const response: any = await GETBASEURL(endpoint, {});
      if (response.status) {
        dispatch(setBaseUrl(response?.data?.baseUrl));
        submit(
          {
            email: userData?.email,
            password: userData?.password,
          },
          response?.data?.baseUrl
        );
        setProgrusModal(false);
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  const submit = async (values: any, BASEURL: any) => {
    try {
      let url = "auth/staff/email-login";
      var loginRes: any = await REGISTERPOST(BASEURL + url, {
        ...values,
        type: "staff",
      });
      if (loginRes.status) {
        dispatch(setToken(loginRes?.data?.token));
        dispatch(
          login({
            data: loginRes?.data,
            auth: true,
          })
        );
        if (loginRes?.data.emailverified) {
          dispatch(setMailWerify());
        }
        navigate("/auth/counter-sale");
      } else {
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#18a762",
          fontFamily: "Regular",
          borderRadius: 4,
          colorTextPlaceholder: "#888c99",
        },
      }}
    >
      <div>
        <Header />
        <Container>
          <Row>
            <Col className="d-none  d-lg-flex justify-content-center align-items-center">
              <div className="signup_box">
                <p>{(t as any)("websitelogin.loginsimplify")}</p>
                <div className="signup_text">
                  {(t as any)("websitelogin.simplifypara")}
                </div>
                <img src={image} alt="" />
              </div>
            </Col>
            <Col className="d-flex justify-content-center align-items-center">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "80%",
                }}
              >
                <div className="signupScreen-Box2">
                  <img src={Logo} alt="" />
                </div>
                <div className="signup_head">
                  {(t as any)("websitelogin.welcome")}
                </div>
                <div className="signup_subhead">
                  {(t as any)("websitesignup.pleasesignup")}
                </div>
                <Form
                  style={{ width: "100%" }}
                  onFinish={onFinish}
                  autoComplete="off"
                >
                  <label className="formLabel">
                    {(t as any)("websitesignup.username")}
                  </label>
                  <Form.Item
                    name="username"
                    rules={[
                      {
                        required: true,
                        message: "Please input your username!",
                      },
                    ]}
                  >
                    <Input className="signup_input" size="large" />
                  </Form.Item>
                  <label className="formLabel">
                    {(t as any)("websitesignup.country")}
                  </label>
                  <Form.Item
                    name="countryid"
                    rules={[
                      {
                        required: true,
                        message: "Please select your country!",
                      },
                    ]}
                  >
                    <Select
                      style={{ height: "45px" }}
                      // placeholder="Select country"
                      showSearch={true}
                      filterOption={(input: any, option: any) =>
                        option.children
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      size="large"
                    >
                      {countries &&
                        countries?.map((country: any) => (
                          <Select.Option key={country.id} value={country.id}>
                            {country?.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </Form.Item>
                  <label className="formLabel">
                    {(t as any)("websitesignup.emailid")}
                  </label>
                  <Form.Item
                    name="email"
                    rules={[
                      {
                        type: "email",
                        message: "Enter a valid email",
                      },
                      {
                        required: true,
                        message: "Please enter your email!",
                      },
                    ]}
                  >
                    <Input className="signup_input" size="large" type="email" />
                  </Form.Item>
                  <label className="formLabel">
                    {(t as any)("websitelogin.password")}
                  </label>
                  <Form.Item
                    name="password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!",
                      },
                    ]}
                    validateStatus={passwordValidation.status}
                    help={passwordValidation.help}
                  >
                    <Input.Password className="signup_input" size="large" />
                  </Form.Item>
                  <Form.Item>
                    <Button
                      size="large"
                      type="primary"
                      block
                      htmlType="submit"
                      className="signup_btn"
                      loading={loding}
                    >
                      {(t as any)("websitecontact.submit")}
                    </Button>
                  </Form.Item>
                </Form>
                <div className="footer_text">
                  {(t as any)("websitesignup.haveaccount")}{" "}
                  <div
                    style={{ color: "#ffbc49" }}
                    onClick={() => navigate("/login")}
                  >
                    {" "}
                    &nbsp; {(t as any)("websitesignup.signin")}
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <br />
      </div>
      {progrusModal && (
        <SettingUpModal
          open={progrusModal}
          company={company}
          staff={staff}
          Counter={Counter}
          product={product}
          percent={percent}
          register={register}
          defualtCompany={(id: any) => defualtCompany(id)}
          defualtStaff={() => defualtStaff()}
          defualtCounter={() => defualtCounter()}
          defualtProduct={() => defualtProduct()}
        />
      )}
    </ConfigProvider>
  );
}

export default SignupScreen;
