import { Button, DatePicker, Form, Input, Space, TimePicker } from 'antd'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FiPlus } from 'react-icons/fi'
import { RiDeleteBin4Line } from 'react-icons/ri'

function CounterForm({ onCancel, onSubmit, counter, counterId }: any) {
    const [form] = Form.useForm()

    const [isBtnLoading, setIsBtnLoading] = useState(false)
    const onFinish = async (val: any) => {
        setIsBtnLoading(true)
        let obj = {
            ...val,
            counterid: counterId
        }
        await onSubmit(obj)
        setIsBtnLoading(false)
    }

    useEffect(() => {
        if (counter) {
            let shiftList = counter?.shiftlist?.map((data: any) => {
                return {
                    from: dayjs(data.fromtime, 'hh:mm'),
                    to: dayjs(data.totime, 'hh:mm'),
                    name: data.name
                };
            });
            form.setFieldsValue({
                name: counter.name,
                sdate: dayjs(counter.sdate, 'YYYY/MM/DD'),
                shift: shiftList
            });
        }
    }, []);

    return (
        <div>
            <Form
                onFinish={onFinish} form={form}
            >
                <Row>
                    <Col className="Table-Txt" md={12}>
                        {"Add/Update Counter List"}
                    </Col>
                    <br />
                    <br />
                    <hr />
                    <Col md={6}>
                        <div className="formItem">
                            <label className="formLabel">Name</label>
                            <Form.Item
                                name="name"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please Select a ledger",
                                    },
                                ]}
                            >
                                <Input size="large" />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="formItem">
                            <label className="formLabel">DATE</label>
                            <Form.Item
                                name="sdate"
                                rules={[
                                    {
                                        required: true,
                                        message: "Please enter date",
                                    },
                                ]}
                            >
                                <DatePicker style={{ width: "100%" }} size="large" />
                            </Form.Item>
                        </div>
                    </Col>
                    <Col md={12}>
                        <div className="formItem">
                            <Form.List name="shift">
                                {(fields, { add, remove }) => (
                                    <>
                                        {fields?.map(({ key, name, ...restField }) => (
                                            <Space key={key} style={{ display: 'flex', margin: 0 }} align="baseline">
                                                <div className="formItem">
                                                    <label className="formLabel">SHIFT NAME</label>
                                                    <Form.Item
                                                        {...restField}
                                                        name={[name, 'name']}
                                                        rules={[{ required: true, message: 'Missing Shift Name' }]}
                                                    >
                                                        <Input placeholder="Shift Name" size="large" />
                                                    </Form.Item>
                                                </div>
                                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                    <div style={{ marginRight: '10px' }}>
                                                        <label className="formLabel">FROM</label>
                                                        <Form.Item name={[name, 'from']} style={{ marginBottom: 10 }}
                                                        >
                                                            <TimePicker size='large' allowClear={false} format="h:mm A" />
                                                        </Form.Item>
                                                    </div>
                                                    <div>
                                                        <label className="formLabel">TO</label>
                                                        <Form.Item name={[name, 'to']} style={{ marginBottom: 10 }}
                                                        >
                                                            <TimePicker size='large' allowClear={false} format="h:mm A" />
                                                        </Form.Item>
                                                    </div>
                                                </div>
                                                <RiDeleteBin4Line color='red' size={18} onClick={() => remove(name)} />
                                            </Space>
                                        ))}
                                        <Form.Item>
                                            <Button type="dashed" size="large" onClick={() => add()} block icon={<FiPlus size={24} />}>
                                                ADD SHIFT
                                            </Button>
                                        </Form.Item>
                                    </>
                                )}
                            </Form.List>
                        </div>
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4}>
                        <Button
                            block
                            size="large"
                            onClick={() => onCancel()}
                        >
                            CANCEL
                        </Button>
                    </Col>
                    <Col md={4}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            size="large"
                            loading={isBtnLoading}
                        >
                            SUBMIT
                        </Button>
                    </Col>
                </Row>
            </Form>
        </div>
    )
}

export default CounterForm