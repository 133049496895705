import { ConfigProvider, Layout, notification } from 'antd'
import { jwtDecode } from "jwt-decode"
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, useNavigate } from 'react-router-dom'
import VerificationModal from '../component/mailVerification'
import Subscription from '../component/subscription/Subscription'
import API from '../config/api'
import { logout } from '../redux/slices/userSlice'
import Company from '../retailExpress/company'
import Counter from '../retailExpress/counter'
import ShiftUpdate from '../retailExpress/counter/component/shiftUpdate'
import ViewCounter from '../retailExpress/counter/component/view_counter'
import Customer from '../retailExpress/customers'
import SaleInvoice from '../retailExpress/invoice'
import SaleInvoiceView from '../retailExpress/invoice/components/view'
import Order from '../retailExpress/order'
import OrderCreate from '../retailExpress/order/components/create'
import OrderView from '../retailExpress/order/components/view'
import RetailProduct from '../retailExpress/product'
import Report from '../retailExpress/report/intex'
import RetailExpress from '../retailExpress/sale'
import StaffProfile from '../retailExpress/staffProfile'
import SubscriptionPage from '../retailExpress/subscription'
import { GET } from '../utils/apiCalls'
import SideBar from './sideBar'

function Navigation() {
    const { user } = useSelector((state: any) => state.User);
    const token: any = user.token;
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { Sider, Content } = Layout;

    const decoded: any = jwtDecode(token);
    let currentDate = new Date();
    let subscriptionEndDate = new Date(decoded?.subscriptionExpiry);

    const [OpenModal, setOpenModal] = useState(false);
    const [collapsed, setCollapsed] = useState(true);

    const fetchUser = async () => {
        try {
            let url = API.GET_USER_DETAILS;
            const response: any = await GET(url, null);
            if (!response?.data?.emailverified) {
                setOpenModal(true);
            } else {
                setOpenModal(false);
            }
        } catch (error) {
            console.error(error)
        }
    }

    const signoutUser = () => {
        notification.warning({
            message: `Your Session Expired`,
            description: "Please sign in again to continue",
        });
        dispatch(logout({}))
        navigate("/");
        return;
    };

    useEffect(() => {
        fetchUser();
    }, [])

    useEffect(() => {
        const checkTokenExpiration = () => {
            if (token) {
                const decoded: any = jwtDecode(token);
                let currentDate = new Date();
                if (decoded.exp && decoded.exp * 1000 < currentDate.getTime()) {
                    signoutUser();
                }
            }
        };

        checkTokenExpiration();
        const interval = setInterval(checkTokenExpiration, 5000);

        return () => clearInterval(interval);
    }, [token, signoutUser]);

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorPrimary: "#18a762",
                    fontFamily: "Regular",
                    borderRadius: 4,
                    colorTextPlaceholder: "#888c99",
                },
            }}
        >
            <Layout>
                <Sider className='d-none d-lg-block'
                    style={{ backgroundColor: "#fff", borderRight: '1px solid #dedede', height: '100vh', overflow: 'scroll' }}
                    width={collapsed ? 250 : 80}
                >
                    <SideBar collapsedChane={() => setCollapsed(!collapsed)} collapsed={collapsed} />
                </Sider>
                <Layout
                    style={{ backgroundColor: "#fff", borderRight: '1px solid #dedede', height: '100vh' }}
                >
                    <Content
                        style={{ background: "#f6f4f0", overflow: "scroll" }}
                    >

                        <Routes>
                            <Route path="/counter-sale" element={(subscriptionEndDate <= currentDate) ? <SubscriptionPage /> : <RetailExpress />} />
                            <Route path="/saleInvoice" element={(subscriptionEndDate <= currentDate) ? <SubscriptionPage /> : <SaleInvoice />} />
                            <Route path="/sale-invoice-view/:id" element={(subscriptionEndDate <= currentDate) ? <SubscriptionPage /> : <SaleInvoiceView />} />
                            <Route path="/staff-profile" element={(subscriptionEndDate <= currentDate) ? <SubscriptionPage /> : <StaffProfile />} />
                            <Route path="/product" element={(subscriptionEndDate <= currentDate) ? <SubscriptionPage /> : <RetailProduct />} />
                            <Route path="/report" element={(subscriptionEndDate <= currentDate) ? <SubscriptionPage /> : <Report />} />
                            <Route path="/counters" element={(subscriptionEndDate <= currentDate) ? <SubscriptionPage /> : <Counter />} />
                            <Route path="/counters/view" element={(subscriptionEndDate <= currentDate) ? <SubscriptionPage /> : <ViewCounter />} />;
                            <Route path="/counters/update" element={(subscriptionEndDate <= currentDate) ? <SubscriptionPage /> : <ShiftUpdate />} />;
                            {/* <Route path="/view-counter" element={<ViewCounter />} />; */}
                            <Route path="/company" element={(subscriptionEndDate < currentDate) ? <SubscriptionPage /> : <Company />} />
                            <Route path="/customer" element={<Customer />} />;
                            <Route path="/order" element={<Order />} />;
                            <Route path="/order-create" element={<OrderCreate />} />;
                            <Route path="/order-view/:id" element={<OrderView />} />;

                            {/* ---------- subscription ---------- */}
                            <Route path="/subscription" element={<Subscription />} />
                            <Route path="/subscription/upgrade" element={<SubscriptionPage />} />
                        </Routes>
                        {OpenModal &&
                            <VerificationModal
                                openModal={OpenModal}
                                user={user}
                                fetchUser={() => fetchUser()}
                            />
                        }
                    </Content>
                </Layout>
            </Layout>
        </ConfigProvider>
    )
}

export default Navigation