import { Col, Row } from "react-bootstrap";
import Image1 from "../../assets/images/image2.png";
import Image2 from "../../assets/images/image3.png";
import "./styles.scss";
import { useTranslation } from "react-i18next"

function Footer() {
  const { t } = useTranslation();

  return (
    <>
      <Row className="footer-Box1 g-0 align-items-center">
        <Col md={12}>
          <div className="footer-Txt1">{(t as any)("websitehome.needhelp")}</div>
          <div className="footer-Txt2">{(t as any)("websiteheader.contact")}</div>
          <div className="footer-Txt1">info@taxgoglobal.com</div>
        </Col>
        <br />
        <Col
         md={{ span: 4, offset: 4 }}
         >
          <Row 
          //className="g-1"
          >
            <Col md={3}>
              {/* <a href="">
                <img className="footer-Img" src={Image1} alt="" />
              </a> */}
            </Col>
            <Col md={6}>
              <div className="footer-Box3">
                <a href="https://play.google.com/store/apps/details?id=com.retailexpress&pcampaignid=web_share">
                  <img className="footer-Img" src={Image2} alt="" />
                </a>
              </div>
            </Col>
            <Col md={3} />
          </Row>
        </Col>
      </Row>
     
    </>
  );
}

export default Footer;
